import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';
import 'slick-carousel/slick/slick.css';

import image from '../../assets/cover_articolo_personalizzato_eng.jpg';
import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../constants/ActionTypes';
import useUserPermissions from '../../hooks/getUserPermissions';
import { useCustomProductConfigurations } from '../../hooks/useCustomProductConfigurations';
import { useCustomProducts } from '../../hooks/useCustomProducts';
import { useProductTotals } from '../../hooks/useProductTotals';
import { useModal } from '../../providers/ModalProvider';
import { registerPaths } from '../../providers/TranslatedSlugProvider';
import { handleMultiCartTypeError } from '../../utils/errorHandling';
import { formatQuantityForView, formatPriceForView as localFormatPrice } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import User from '../../utils/user';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import QuantitySelector from '../Inputs/QuantitySelector/QuantitySelector';
import Drawer from '../layout/Drawer/Drawer';

import AvailabilitySelector from './Components/AvailabilitySelector';

const MIN_QUANTITY_TO_ORDER = 1;

export const CUSTOM_PRODUCT_IMAGE_SRC = image;

const CustomProduct = (props) => {
  const { userPermissions } = useUserPermissions({});
  const [activeColor, setActiveColor] = useState('');
  const [colorname, SetColorname] = useState('');
  const [cID, setCid] = useState(0);
  const [summary, SetSummary] = useState(0);
  const [QTY, _SetQTY] = useState(1);
  const [precise, Setprecise] = useState(0);
  const { t, i18n } = useTranslation();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);
  const quantityLoader = useLoader({ height: 82 }, false);

  const dispatch = useDispatch();

  const [selectedCustomProduct, setSelectedCustomProduct] = useState(null);
  const { isLoading: isLoadingCustomProducts, customProducts } = useCustomProducts({});
  const { isLoading: isLoadingConfigurations, availableColors } = useCustomProductConfigurations({
    customProduct: selectedCustomProduct,
  });

  const [searchCustomProductQuery, setSearchCustomProductQuery] = useState('');
  const [filteredCustomProduct, setFilteredCustomProduct] = useState(customProducts);

  useEffect(() => {
    if (!customProducts?.length) {
      setFilteredCustomProduct([]);
    }

    if (!searchCustomProductQuery?.length) {
      setFilteredCustomProduct(customProducts || []);
    }

    setFilteredCustomProduct(
      customProducts.filter((cP) => {
        return (
          cP.short_title.toLowerCase().indexOf(searchCustomProductQuery.toLowerCase()) > -1 ||
          cP.namealias.toLowerCase().indexOf(searchCustomProductQuery.toLowerCase()) > -1
        );
      })
    );
  }, [searchCustomProductQuery, customProducts]);

  const {
    price,
    unitPrice,
    totalPrice,
    totalSupplement,
    totalSupplement2,
    isAvailableJson: is_available_json,
    setIsAvailableJson: SetIs_available_json,
    globalAvailable,
    available,
    setAvailable: Setavailable,
    isOption: isoption,
    supplement: suppliment,
    supplement2,
    isLoading: loadingProductTotal,
  } = useProductTotals({
    mainProduct: selectedCustomProduct,
    colorName: colorname,
    qty: QTY,
  });

  const formatPriceForView = (amount) => localFormatPrice(amount, User.getCurrency(userDataDetail));

  const SetQTY = (q) => {
    if (q < 0) q = MIN_QUANTITY_TO_ORDER;
    _SetQTY(q);
  };

  /*if (userDataDetail?.view_credit === false) {
    window.location.href = '/';
  }*/

  const handleColorSelect = (color, Name, image, id) => {
    if (id == cID) {
      setCid(0);
      setActiveColor('');
      SetColorname('');
    } else {
      setCid(id);
      setActiveColor(color);
      SetColorname(Name);
    }
  };

  const Richiedi_rocche_preciseChange = () => {
    if (precise == 0) {
      Setprecise(1);
    } else {
      Setprecise(0);
    }
  };

  const closeminicaet = () => {
    SetSummary(0);
    SetQTY(MIN_QUANTITY_TO_ORDER);
    Setprecise(0);
    SetIs_available_json([]);
  };

  const handleAccordation = (id, id_next, is_open = 'close') => {
    if (id == 'accordation_1') {
      let accordation_12 = document?.getElementById('accordation_12');
      accordation_12.classList.toggle('header_stiky');
    }

    let component = document?.getElementById(id);
    var acc = document.getElementById(id_next);

    if (is_open == 'open') {
      acc.classList.add('arrow_rottate');
      component.style.maxHeight = component.scrollHeight + 500 + 'px';
    } else {
      acc.classList.toggle('arrow_rottate');
      acc.style.maxHeight = 500 + 'px';

      if (component.style.maxHeight) {
        component.style.maxHeight = null;
      } else {
        component.style.maxHeight = component.scrollHeight + 50 + 'px';
      }
    }
  };
  const modal = useModal();

  const openMiniCart = () => {
    if (cID === 0) {
      modal.showAttention({ title: t('Si prega di selezionare il colore'), confirmButtonText: 'OK' });
    } else {
      SetSummary(cID);
    }
  };

  const addtoCart = () => {
    modal.showLoading({
      title: t('popup.reorderAdding'), //'Adding product to the cart',
      allowOutsideClick: false,
    });

    axios
      .post('/v1/add-to-cart', {
        config_id: selectedCustomProduct?.configid,
        color_id: colorname,
        product_name: 'Prodotti personalizzati',
        product_price: unitPrice,
        suppliment: suppliment,
        suppliment2: supplement2,
        quantity: QTY,
        client_id: userDataDetail.id,
        is_available_json: JSON.stringify({
          suppliment: suppliment,
          suppliment2: supplement2,
          available: available,
          isprecise: precise,
          isoption: isoption,
          ItemId: selectedCustomProduct?.item_id,
          is_available_json: is_available_json,
        }),
        precise_integer: precise,
        avelphysical: available,
        is_custom_product: true, // TODO: keep track of this one
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: response.data.result.data?.cartQuantity || 0,
        });
        modal.showSuccess({
          subtitle: t('popup.productAdded'), //'Questo prodotto è stato aggiunto al carrello con successo',
          confirmButtonText: t('ok_got_it'),
          onConfirm: () => closeminicaet(),
        });
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.data?.result?.type === 'multicart_type_error') {
          handleMultiCartTypeError(userDataDetail, modal, closeminicaet, dispatch, t);
        } else {
          alert(error?.response?.data?.message || 'Something went wrong');
        }
      });
  };

  const updateQTY = (set_qty) => {
    SetQTY(parseFloat(set_qty));
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  var product_slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 766,
        settings: {
          dots: false,
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (isLoadingCustomProducts) {
    return (
      <div className="loader_wrapper">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <div className="main_content product_detail">
        <div className="product_section">
          <div className="product_wrappper container">
            <div className="product_title mobile_section">
              <span>{t('collezione')}</span>
              <h2>{t('Prodotti personalizzati')}</h2>
            </div>
            <div className="product_slider product_media">
              <img src={CUSTOM_PRODUCT_IMAGE_SRC} alt="Immagine" />
            </div>
            <div className="product_info">
              <div className="product_title desktop_section">
                <span>{t('collezione')}</span>
                <h2>{t('Prodotti personalizzati')}</h2>
              </div>
              <div className="product_tab">
                <span className="tab_title active">{t('configura_prodotto')}</span>
                <div className="tab_content configure">
                  <div className="accordion_info">
                    <div
                      className="accordion_title"
                      id="test_1"
                      onClick={() => handleAccordation('accordation_1', 'test_1')}
                    >
                      <h3>{selectedCustomProduct ? selectedCustomProduct.namealias : t('seleziona_articolo')}</h3>
                      <span>
                        {customProducts.length} {t('articoli_disponibili')}
                      </span>
                    </div>
                    <div className="accordion_content" id="accordation_1">
                      <div className="select_section custom-scrollbar">
                        <div className="select_header " id="accordation_12">
                          <div className="select_row">
                            <div className="select_title name">{t('Nome Articolo')}</div>
                            <div className="select_title name">{t('Descrizione')}</div>
                          </div>
                        </div>
                        <div className="custom-product-search">
                          <div className="search-block">
                            <input
                              type="text"
                              placeholder={t('Digita il nome del prodotto')}
                              value={searchCustomProductQuery}
                              onChange={(event) => setSearchCustomProductQuery(event.target.value)}
                            />
                            <span></span>
                          </div>
                        </div>
                        <div className="select_body header_stiky_1">
                          {filteredCustomProduct.map((cProduct, idx) => (
                            <div
                              key={cProduct.id}
                              onClick={() => {
                                setSelectedCustomProduct(cProduct);
                                  handleAccordation('accordation_2', 'test_2', 'open');
                                  handleAccordation('accordation_1', 'test_1');
                              }}
                              className={`select_row ${
                                selectedCustomProduct?.id === cProduct.id && 'active_selecteed_color'
                              }`}
                            >
                              <div className="select_column" data-label="Nome">
                                {cProduct.short_title}
                              </div>
                              <div className="select_column" data-label="Nome">
                                {cProduct.namealias}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion_info colore" id="accordion_info_color">
                    <div
                      className="accordion_title "
                      id="test_2"
                      onClick={() => handleAccordation('accordation_2', 'test_2')}
                    >
                      <h3>
                        {' '}
                        {colorname ? (
                          <>
                            {t('color')}: {colorname}
                          </>
                        ) : (
                          t('seleziona_colore')
                        )}
                      </h3>

                      <div>
                        <span className="disponibili_span">
                          {availableColors.length > 0 ? availableColors.length + ' ' + t('colori_disponibili') : ''}
                        </span>
                      </div>
                    </div>
                    <p>{t('immagini')}</p>

                    <div className="accordion_content" id="accordation_2">
                      {isLoadingConfigurations ? (
                        <div className="filter_color_loader">
                          <div className="loader"></div>
                        </div>
                      ) : (
                        <Colorshow
                          colorList={availableColors}
                          handleColorSelect={handleColorSelect}
                          activeColor={activeColor}
                          cid={cID}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_action mobile_section">
                {userPermissions.canAddCustomProductToCart ? <p>{t('per_ordinire_il_seguente')}</p> : null}
                <div className="login_action">
                  {userDataDetail?.id === undefined ? (
                    <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                      {t('Aggiungi_al_carrello')}
                    </Link>
                  ) : userPermissions.canAddCustomProductToCart ? (
                    agentDataDetail && agentDataDetail?.id === userDataDetail?.id ? (
                      <Link className="no-padding" to="/dashboard-list">
                        <PrimaryButton fullWidth>{t('agent.select-client')}</PrimaryButton>
                      </Link>
                    ) : (
                      <PrimaryButton fullWidth onClick={openMiniCart}>
                        {t('Aggiungi_al_carrello')}
                      </PrimaryButton>
                    )
                  ) : null}
                </div>
              </div>
              <div className="order_action desktop_section">
                {userPermissions.canAddCustomProductToCart ? <p>{t('per_ordinire_il_seguente')}</p> : null}
                <div className="login_action">
                  {userDataDetail?.id === undefined ? (
                    <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                      {t('Aggiungi_al_carrello')}
                    </Link>
                  ) : userPermissions.canAddCustomProductToCart ? (
                    agentDataDetail && agentDataDetail?.id === userDataDetail?.id ? (
                      <Link className="no-padding" to="/dashboard-list">
                        <PrimaryButton fullWidth>{t('agent.select-client')}</PrimaryButton>
                      </Link>
                    ) : (
                      <PrimaryButton fullWidth onClick={openMiniCart}>
                        {t('Aggiungi_al_carrello')}
                      </PrimaryButton>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {cID >= 1 && summary >= 1 ? (
        <Drawer
          onClose={closeminicaet}
          title={t('Riepilogo')}
          content={
            <div className="product_summary">
              <div>
                <div className="product_details">
                  <div>
                    <h4 className="product_name">{t('Prodotti personalizzati')}</h4>
                    <p>
                      <span>{t('color')}:</span>
                      <span> {colorname}</span>
                    </p>
                  </div>
                  <div>
                    <h5 className="details_title">{t('Definisci_quantita')}</h5>
                    <p>{t('Scegli_kg')}</p>
                  </div>
                  <div className="product_qty">
                    <h4>Kg</h4>
                    <QuantitySelector QTY={QTY} updateQTY={updateQTY} />
                  </div>
                  {userDataDetail.PRECISECONES_CGK_2014 == 1 ? (
                    <div className="request_precise">
                      <label>
                        <input onChange={(e) => Richiedi_rocche_preciseChange()} type="checkbox" />
                        <span className="checkbox" />
                      </label>
                      <div>
                        <span>{t('Rocche_precise')}</span>

                        {precise >= 1 ? (
                          <>
                            {/*<h5>Numero rocche</h5>
                            <div className="product_qty">
                              <p>
                                <span
                                  className="minus"
                                  onClick={(e) => {
                                    if (precise >= 2) Setprecise(precise - 1);
                                  }}
                                >
                                  -
                                </span>
                                <input
                                  key={'asdadd'}
                                  value={precise}
                                  onChange={(e) => {
                                    e.target.value <= 99999 && e.target.value >= 1
                                      ? Setprecise(e.target.value)
                                      : Setprecise(1);
                                  }}
                                  type="number"
                                />
                                <span
                                  className="add"
                                  onClick={(e) => {
                                    Setprecise(precise + 1);
                                  }}
                                >
                                  +
                                </span>{' '}
                              </p>
                            </div>*/}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <AvailabilitySelector
                  globalAvailable={globalAvailable}
                  QTY={QTY}
                  available={available}
                  Setavailable={Setavailable}
                />
                <div className="required_fields">
                  <p>{t('campi_obbligatori')}</p>
                </div>
                <div className="total_price">
                  <div>
                    <p>{t('prezzo_unitario')}</p>
                    <p>{formatPriceForView(price)}</p>
                  </div>
                  <div>
                    <p>{t('Quantità')}</p>
                    <p>{formatQuantityForView(QTY)}</p>
                  </div>
                  {totalSupplement?.[0] ? (
                    <div>
                      <p>{t('Supplemento')}</p>
                      {loadingProductTotal ? (
                        <div className="loader" style={{ width: '18px', height: '18px', borderWidth: '2px' }}></div>
                      ) : (
                        <p>{formatPriceForView(totalSupplement?.[0])}</p>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    <h4>{t('common.total')}</h4>
                    {loadingProductTotal ? (
                      <div className="loader" style={{ width: '18px', height: '18px', borderWidth: '2px' }}></div>
                    ) : (
                      <h4>{formatPriceForView(totalPrice)}</h4>
                    )}
                  </div>
                  <p>{t('common.spese_spedizione')}</p>
                </div>
                <div className="add_cart_btn active">
                  {price >= 1 && QTY >= 1 && available != 'option' ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        addtoCart();
                      }}
                    >
                      {t('Aggiungi_al_carrello')}
                    </a>
                  ) : (
                    quantityLoader.asJsx || (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        href="#"
                        style={{ cursor: 'default', opacity: 0.5 }}
                      >
                        {t('Aggiungi_al_carrello')}
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

function Colorshow({ colorList, handleColorSelect, activeColor, cid }) {
  const { t, i18n } = useTranslation();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredColours, setFilteredColours] = useState(colorList);

  useEffect(() => {
    if (!colorList?.length) {
      setFilteredColours([]);
    }

    if (!searchQuery?.length) {
      setFilteredColours(colorList || []);
    }

    setFilteredColours(
      colorList.filter((c) => {
        return c.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
      })
    );
  }, [searchQuery, colorList]);

  return (
    <>
      <div className="color_section custom-scrollbar">
        {colorList?.length > 0 ? (
          <div className="search-block">
            <input
              type="text"
              placeholder={t('Digita nome del colore')}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
            <span></span>
          </div>
        ) : null}
        {filteredColours?.map((color, idx) => {
          return (
            <div
              key={color?.id}
              className={`color_items ${color?.id == cid ? 'active_selecteed_color' : 'deactive_selecteed_color'}`}
              onClick={() =>
                handleColorSelect(
                  `#${color?.hex?.replace('#', '')}`,
                  color?.inventcolorid,
                  color?.hex_image_url,
                  color.id
                )
              }
            >
              <div className="color_info">
                <span className="code">{color?.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CustomProduct;