import i18next from 'i18next';
export const getTranslated = (i18n, object, fields_by_language, default_field) => {
    const language = i18n.language;

    if (!object) {
        return null;
    }

    if (language in fields_by_language && fields_by_language[language] in object) {
        return object[fields_by_language[language]];
    }

    return default_field in object ? object[default_field] : null;
};

export const translateProblemType = (id) => {
    let name = '';
    let name_en = '';

    switch (id) {
        //Inizio tipi di problemi ticket-online
        case 'DIP/RC - Scorrev':
            name = 'Scorrevolezza';
            name_en = 'Smoothness';
            break;
        case 'FIL - Gira':
            name = 'Filato che gira';
            name_en = 'Spinning yarn';
            break;
        case 'FIL - Grovigli':
            name = 'Grovigli';
            name_en = 'Tangles';
            break;
        case 'FIL - Inquinam':
            name = 'Inquinamento';
            name_en = 'Pollution';
            break;
        case 'FIL - Resistenza':
            name = 'Resistenza bassa';
            name_en = 'Low resistance';
            break;
        case 'FIL - Titolo <>':
            name = 'Titolo diverso';
            name_en = 'Different title';
            break;
        case 'SPEDIZ. eccesso':
            name = 'Spedito in eccesso';
            name_en = 'Over shipped';
            break;
        case 'TINT - Barrat':
            name = 'Barratura';
            name_en = 'Striping';
            break;
        case 'TINT - Fuori tono':
            name = 'Fuori tono';
            name_en = 'Off tone';
            break;
        case 'TINT - Solid colore':
            name = 'Solidità colore';
            name_en = 'Color fastness';
            break;
        //Fine tipi di problemi ticket-online
        case 'CONF - Confezione':
            name = 'Problemi confezione';
            name_en = 'Packaging problems';
            break;
        case 'DIP/RC - Densita roc':
            name = "Differenza densita' rocche";
            name_en = 'Difference in bobbin density';
            break;
        case 'DIP/RC - Paraff':
            name = 'Eccesso di paraffina';
            name_en = 'Excess paraffin';
            break;
        case 'DIP/RC - Rc sporche':
            name = 'Rocche sporche';
            name_en = 'Dirty bobbins';
            break;
        case 'FIL - Asole':
            name = 'Asole';
            name_en = 'Loops';
            break;
        case 'FIL - barratura':
            name = 'barratura su greggio';
            name_en = 'Raw striping';
            break;
        case 'FIL - Componenti':
            name = 'Componenti errati';
            name_en = 'Wrong components';
            break;
        case 'FIL - Nodi':
            name = 'Nodi';
            name_en = 'Knots';
            break;
        case 'FIL - Peeling':
            name = 'Eccesso di pelosità';
            name_en = 'Excess fuzziness';
            break;
        case 'FIL - Polvere':
            name = 'Accumulo di polvere sul guidafilo o in fase di lavorazione';
            name_en = 'Dust accumulation on the thread guide or during processing';
            break;
        case 'FIL - Regolarita':
            name = "Regolarita'";
            name_en = 'Regularity';
            break;
        case 'FIL - Ritorcitura':
            name = "Problemi in ritorcitura'";
            name_en = 'Twisting problems';
            break;
        case 'FIL - Rotti/Tagliati':
            name = "Fili rotti o Tagliati";
            name_en = 'Broken or cut threads';
            break;
        case 'FIL - Torsione':
            name = "Torsione con filo errato";
            name_en = 'Twisting with wrong thread';
            break;
        case 'FIL- Umidità':
            name = "troppo umido o troppo secco";
            name_en = 'Too humid or too dry';
            break;
        case 'ORD - Err commerc':
            name = "Errore commerciale";
            name_en = 'Commercial error';
            break;
        case 'ORD - Ns richiesta':
            name = "Nostra richiesta";
            name_en = 'Our request';
            break;
        case 'ORD - Reso concordat':
            name = "Reso concordato con commerciale perché non serve piu'";
            name_en = 'Return agreed with commercial because no longer needed';
            break;
        case 'ORD -Annullato':
            name = "Ordine annullato";
            name_en = 'Order canceled';
            break;
        case 'ORD- errore cliente':
            name = "Errore cliente";
            name_en = 'Customer error';
            break;
        case 'ORD-Ritardo':
            name = "Spedizione in ritardo";
            name_en = 'Late shipment';
            break;
        case 'RES - Annullato':
            name = "Ordine annullato";
            name_en = 'Order canceled';
            break;
        case 'RES - Asole':
            name = "Asole";
            name_en = 'Loops';
            break;
        case 'RES - Barratura':
            name = "barratura greggio";
            name_en = 'Raw striping';
            break;
        case 'RES - Barratura tint':
            name = "Barratura tintura";
            name_en = 'Dye striping';
            break;
        case 'RES - Componenti':
            name = "Componenti errati";
            name_en = 'Wrong components';
            break;
        case 'RES - Diff.colore':
            name = "Differenza coore tra rocca e rocca";
            name_en = 'Color difference between bobbins';
            break;
        case 'RES - eccesso':
            name = "spedito in eccesso";
            name_en = 'Over shipped';
            break;
        case 'RES - Err commerc':
            name = "Errore commerciale";
            name_en = 'Commercial error';
            break;
        case 'RES - errore cliente':
            name = 'Errore cliente';
            name_en = 'Customer error';
            break;
        case 'RES - Errore MAGA':
            name = 'Errore dovuto al Magazzino';
            name_en = 'Warehouse error';
            break;
        case 'RES - Est/Int rocca':
            name = 'Esterno - Interno rocca';
            name_en = 'Outside - Inside bobbin';
            break;
        case 'RES - etichette':
            name = 'Errore confezione-etichettatura';
            name_en = 'Packaging-labeling error';
            break;
        case 'RES - Fuori tono':
            name = 'Fuori tono';
            name_en = 'Off tone';
            break;
        case 'RES - Grovigli':
            name = 'Grovigli';
            name_en = 'Tangles';
            break;
        case 'RES - Inquinam':
            name = 'Inquinamento';
            name_en = 'Pollution';
            break;
        case 'RES - mano':
            name = 'Mano';
            name_en = 'Hand';
            break;
        case 'RES - Nodi':
            name = 'Nodi';
            name_en = 'Knots';
            break;
        case 'RES - Ns richiesta':
            name = 'Nostra richiesta';
            name_en = 'Our request';
            break;
        case 'RES - Polvere':
            name = 'Accumulo di polvere sul guidafilo o in fase di lavorazione';
            name_en = 'Dust accumulation on the thread guide or during processing';
            break;
        case 'RES - Regolarità':
            name = "Regolarita'";
            name_en = 'Regularity';
            break;
        case 'RES - Resistenza':
            name = "Resistenza bassa";
            name_en = 'Low resistance';
            break;
        case 'RES - Reso concordat':
            name = "Reso concordato con commerciale perché non serve piu'";
            name_en = 'Return agreed with commercial because no longer needed';
            break;
        case 'RES - Ritardo':
            name = "Spedizione in ritardo";
            name_en = 'Late shipment';
            break;
        case 'RES - Ritorcitura':
            name = "Ritorcitura";
            name_en = 'Twisting';
            break;
        case 'RES - Rotti/tagliati':
            name = "Fili rotti o Tagliati";
            name_en = 'Broken or cut threads';
            break;
        case 'RES - scorevolezza':
            name = "scorrevolezza";
            name_en = 'Smoothness';
            break;
        case 'RES - Solid colore':
            name = "Solidità colore";
            name_en = 'Color fastness';
            break;
        case 'RES - Solid sfreg':
            name = "Solidità sfregamento";
            name_en = 'Rubbing fastness';
            break;
        case 'RES - Stabilità':
            name = "Filato che gira";
            name_en = 'Spinning yarn';
            break;
        case 'RES - Titolo':
            name = "Titolo diverso";
            name_en = 'Different title';
            break;
        case 'RES - Torsione':
            name = "Torsione con filo errato";
            name_en = 'Twisting with wrong thread';
            break;
        case 'RES - Umidità':
            name = "troppo umido o troppo secco";
            name_en = 'Too humid or too dry';
            break;
        case 'SPEDIZ Errore MAGA':
            name = "Errore dovuto al Magazzino";
            name_en = 'Warehouse error';
            break;
        case 'SPEDIZ- etichette':
            name = "Errore confezione-etichettatura";
            name_en = 'Packaging-labeling error';
            break;
        case 'TINT - Diff.colore':
            name = "Differenza colore tra rocca e rocca";
            name_en = 'Color difference between bobbins';
            break;
        case 'TINT - Est/Int rocca':
            name = "Esterno - Interno rocca";
            name_en = 'Outside - Inside bobbin';
            break;
        case 'TINT - mano':
            name = "Mano";
            name_en = 'Hand';
            break;
        case 'TINT - Solid sfreg':
            name = "Solidita' sfregamento";
            name_en = 'Rubbing fastness';
            break;
        default:
            break;
    }

    const currentLanguage = i18next.language;
    return currentLanguage === 'it' ? name : name_en;
};  