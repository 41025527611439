import React,{useEffect, useMemo, useState} from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { defaultCountries } from 'react-international-phone';
import { getCountryCallingCode } from 'libphonenumber-js';
import countryUtil from 'i18n-iso-countries';
import itCountries from 'i18n-iso-countries/langs/it.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
import axios from 'axios';
import { useModal } from '../../../providers/ModalProvider';

import { GOOGLE_MAPS_API_KEY } from '../../../constants/google';
import { getTranslated } from '../../../utils/translations';
import PhoneLocalInput from '../../Inputs/PhoneLocalInput';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';
import Tooltip from '../../shared/Tooltip/Tooltip';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';


const CompanyInformation = ({
  className,
  formDetails,
  formData,
  handleFormDataChange,
  handlePlaceSelected,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: { types: ['address'] },
    onPlaceSelected: handlePlaceSelected,
  });

  useEffect(() => {
    if (!formData.selecttypeClient) {
      handleFormDataChange('selecttypeClient', '1'); // Imposta il valore a '1' se non è definito
    }
    
  }, [formData.selecttypeClient, handleFormDataChange]);

  const FormTitles = [t('register.step-1'), t('register.step-2'), t('register.step-3')];
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const isFirstPage = useMemo(() => page === 0, [page]);
  const isLastPage = useMemo(() => page === FormTitles.length - 1, [page]);
  
  const [verified, setVerified] = useState(false);

  //Gestione country
  countryUtil.registerLocale(itCountries);
  countryUtil.registerLocale(enCountries);
  
  const [countryOptions, setCountryOptions] = useState([]);
  
  useEffect(() => {
    const countryCodes = Object.keys(countryUtil.getAlpha2Codes());
    const mappedCountries = countryCodes.map((code) => ({
      value: code, 
      label: countryUtil.getName(code, i18n.language) || code,
    }));

    setCountryOptions(mappedCountries);
  }, [i18n.language]);


  //Gestion prefissi telefonici
  const [phone, setPhone] = useState({
    number: formData.mobile_no || '',
    countryCode: formData.country || 'IT',
  });
  
  
  const handlePhoneChange = (value, country) => {
    setPhone(value);
    handleFormDataChange('mobile_no', value.number);
  };
  const modal = useModal();
  
  const handleNextPageClick = async () => {
    if (isFirstPage && formData.alreadyClient === '1') {
      setIsLoadingNextPage(true);


      try {
        const res = await axios.post('/v1/registration-check-customer', {
          code: formData.code,
        });
        if(res?.data?.result?.data?.isAlreadyMaster){
          modal.showAttention({
            title: t('check_login.utente_master_title'),
            subtitle: (
              <>
                {t('check_login.utente_master_subtitle')}
                <br />
                {t('check_login.contact_email_master')} <b>{res?.data?.result?.data?.email}</b>
              </>
            ),
          });
          return;
        }
        if(!res?.data?.result?.data?.isCustomer) {
          modal.showAttention({
            title: t('check_login.codice_filmar_not_found_title'),
            subtitle: (
              <>
                {t('check_login.codice_filmar_not_found_subtitle')}
              </>
            ),
          });
          return;
        }
        
        if(res.data.result.data.business_name!=''){
          handleFormDataChange('selecttypeClient', '0');
          handleFormDataChange('business_name', res.data.result.data.business_name);
          if(res.data.result.data.email!=''){
            handleFormDataChange('email_company', res.data.result.data.email);
          }
          handleFormDataChange('role_in_the_company', res.data.result.data.role_in_the_company);
          handleFormDataChange('vat_number', res.data.result.data.vat_number);
        }
        else{
          handleFormDataChange('selecttypeClient', '1');
          if(res.data.result.data.email!=''){
            //handleFormDataChange('email_personal', res.data.result.data.email);
          }
        }
        if(res.data.result.data.first_name!=''){
          handleFormDataChange('name_register', res.data.result.data.first_name);
        }
        if(res.data.result.data.last_name!=''){
          handleFormDataChange('surname_register', res.data.result.data.last_name);
        }
        if(res.data.result.data.mobile_no!=''){
          const mobileNo = res.data.result.data.mobile_no;
          const countryCodeIso2= countryUtil.alpha3ToAlpha2(res.data.result.data.country, i18n.language);
          const countryCode = countryCodeIso2 || 'IT';
          const prefix = `+${getCountryCallingCode(countryCode)}`;

          if (mobileNo.startsWith(`+${prefix}`)) {
            handleFormDataChange('mobile_no', mobileNo);
          } else {
            handleFormDataChange('mobile_no', `+${prefix}${mobileNo}`);
          }
        }
        if(res.data.result.data.codice_fiscale!=''){
          handleFormDataChange('codice_fiscale', res.data.result.data.codice_fiscale);
        }
        if(res.data.result.data.province!=''){
          handleFormDataChange('province', res.data.result.data.province);
        }
        if(res.data.result.data.city!=''){
          handleFormDataChange('city', res.data.result.data.city);
        }
        if(res.data.result.data.postal_code!=''){
          handleFormDataChange('postal_code', res.data.result.data.postal_code);
        }
        if(res.data.result.data.address!=''){
          handleFormDataChange('address', res.data.result.data.address);
        }
        if(res.data.result.data.reason_for_registration!=''){
          handleFormDataChange('reason_for_registration', res.data.result.data.reason_for_registration);
        }
        if(res.data.result.data.codice_sdi!=''){
          handleFormDataChange('codice_sdi', res.data.result.data.codice_sdi);
        }
        if(res.data.result.data.role_in_the_company!=''){
          handleFormDataChange('role_in_the_company', res.data.result.data.role_in_the_company);
        }
        if(res.data.result.data.customer_type!=''){
          handleFormDataChange('customer_type', res.data.result.data.customer_type);
        }
        if(res.data.result.data.production_type!=''){
          handleFormDataChange('production_type', res.data.result.data.production_type);
        }
        if(res.data.result.data.revenue!=''){
          handleFormDataChange('revenue', res.data.result.data.revenue);
        }
        if(res.data.result.data.number_of_machines!=''){
          handleFormDataChange('number_of_machines', res.data.result.data.number_of_machines);
        }
        if(res.data.result.data.number_of_employees!=''){
          handleFormDataChange('number_of_employees', res.data.result.data.number_of_employees);
        }
        if(res.data.result.data.production_for!=''){
          handleFormDataChange('production_for', res.data.result.data.production_for);
        }
        if(res.data.result.data.country!=''){
          let countryISO3 = res.data.result.data.country;
          handleFormDataChange('country3',countryISO3);
          try {
            const countryISO2 = countryUtil.alpha3ToAlpha2(countryISO3, i18n.language);
            if (countryISO2) {
              handleFormDataChange('country',countryISO2);
            } else {
              console.error('Unable to find ISO2 code for country:', countryISO3);
            }
            
          } catch (error) {
            console.error('Error processing country code:', error);
          }
        }
        setVerified(true); // Per abilitare i campi dopo la verifica del codice utente
      } catch (err) {
        console.error('error', err);
        //setErrors(err?.response?.data?.result?.errors || {});
        return;
      } finally {
        setIsLoadingNextPage(false);
      }
    }
    setPage((page) => (!isLastPage ? page + 1 : page)); 
  };
  //TO DO - rimuovere i campi Codice SDi e pec per i clienti esteri
  return (
    <div className={className}>
      <SelectInput
        id="alreadyClient"
        name="alreadyClient"
        label={t('register.already-client')}
        value={formData.alreadyClient}
        updateValue={(value) => handleFormDataChange('alreadyClient', value)}
        options={[
          { value: '1', label: t('register.yes') },
          { value: '0', label: t('register.no') },
        ]}
        errors={errors?.alreadyClient}
      />
      {formData.alreadyClient === '1' ? (
        <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '8px' }}>{t('register.codice_cliente_label')}</p> {}
          <Tooltip text={t('register_tooltip_codice_filmar')} />
        </div>
        <TextInput
          id="code"
          name="code"
          placeholder={t('register.client-code')}
          value={formData.code}
          updateValue={(value) => handleFormDataChange('code', value)}
          errors={errors?.code}
        />
        <PrimaryButton type="button" onClick={handleNextPageClick}>
          {t('verifica_utente')}
        </PrimaryButton>
        </>
      ) :null}
      {(verified  || formData.alreadyClient === '0' )&& (
        <>
          <SelectInput
            id="reason_for_registration"
            name="reason_for_registration"
            label={t('register.registration-reason')}
            value={formData.reason_for_registration}
            updateValue={(value) => handleFormDataChange('reason_for_registration', value)}
            options={(formDetails?.reason_for_registration || []).map((r) => ({
              value: r.key,
              label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
            }))}
            errors={errors?.reason_for_registration}
          />
          <SelectInput
            id="selecttypeClient"
            name="selecttypeClient"
            label={t('register.type-client')}
            value={formData.selecttypeClient}
            updateValue={(value) => handleFormDataChange('selecttypeClient', value)}
            options={[
              { value: '0', label: t('register.type_company') },
              { value: '1', label: t('register.type_privato') },
            ]}
            errors={errors?.selecttypeClient}
          />
          <TextInput
            id="name_register"
            name="name_register"
            placeholder={t('name_required')}
            value={formData.name_register}
            updateValue={(value) => handleFormDataChange('name_register', value)}
            required={true}
            errors={errors?.name_register}
          />
          <TextInput
            id="surname_register"
            name="surname_register"
            placeholder={t('surname_required')}
            value={formData.surname_register}
            updateValue={(value) => handleFormDataChange('surname_register', value)}
            required={true}
            errors={errors?.surname_register}
          />
          <SelectInput
            id="role_in_the_company"
            name="role_in_the_company"
            label={t('register.role-in-the-company')}
            value={formData.role_in_the_company}
            updateValue={(value) => handleFormDataChange('role_in_the_company', value)}
            options={(formDetails?.role_in_the_company || []).map((r) => ({
              value: r.key,
              label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
            }))}
            errors={errors?.role_in_the_company}
          />
          {formData.selecttypeClient === '1' ? (
            <>
            <TextInput
              id="email_personal"
              name="email_personal"
              placeholder={t('indirizzo_email_personal_required')}
              value={formData.email_personal}
              updateValue={(value) => handleFormDataChange('email_personal', value)}
              errors={errors?.email_personal}
            />
            <PhoneLocalInput
              id="mobile_no"
              name="mobile_no"
              value={formData.mobile_no}
              onChange={(value, meta) => {
                handleFormDataChange('mobile_no', value);
              }}
              placeholder={t('telefono_required')}
              errors={errors?.mobile_no}
            />
            <TextInput
              id="codice_fiscale"
              name="codice_fiscale"
              placeholder={t('codice_fiscale_required')}
              value={formData.codice_fiscale}
              updateValue={(value) => handleFormDataChange('codice_fiscale', value)}
              errors={errors?.codice_fiscale}
              //forwardedRef={ref}
            />
            <TextInput
              id="email_pec"
              name="email_pec"
              placeholder={t('register.email_pec_no_required')}
              value={formData.email_pec}
              updateValue={(value) => handleFormDataChange('email_pec', value)}
              errors={errors?.email_pec}
            />
            </>
            
          ) : (
          <>
            <TextInput
              id="email_company"
              name="email_company"
              placeholder={t('indirizzo_email_nominativa_company_required')}
              value={formData.email_company}
              updateValue={(value) => handleFormDataChange('email_company', value)}
              errors={errors?.email_company}
            />
            <TextInput
              id="email_personal"
              name="email_personal"
              placeholder={t('indirizzo_email_company')}
              value={formData.email_personal}
              updateValue={(value) => handleFormDataChange('email_personal', value)}
              errors={errors?.email_personal}
            />
            <PhoneLocalInput
              id="mobile_no"
              name="mobile_no"
              value={formData.mobile_no}
              onChange={(value, meta) => {
                handleFormDataChange('mobile_no', value);
              }}
              placeholder={t('telefono_required')}
              errors={errors?.mobile_no}
            />
            <TextInput
              id="business_name"
              name="business_name"
              placeholder={t('reglone_societe_required')}
              value={formData.business_name}
              updateValue={(value) => handleFormDataChange('business_name', value)}
              required={true}
              errors={errors?.business_name}
            />
            <TextInput
              id="vat_number"
              name="vat_number"
              placeholder={t('partial_vat_required')}
              value={formData.vat_number}
              updateValue={(value) => handleFormDataChange('vat_number', value)}
              errors={errors?.vat_number}
            />
            <TextInput
              id="codice_fiscale_company"
              name="codice_fiscale_company"
              placeholder={t('codice_fiscale_company')}
              value={formData.codice_fiscale_company}
              updateValue={(value) => handleFormDataChange('codice_fiscale_company', value)}
              errors={errors?.codice_fiscale_company}
              //forwardedRef={ref}
            />
            <TextInput
              id="codice_sdi"
              name="codice_sdi"
              placeholder={t('codice_sdi_required')}
              value={formData.codice_sdi}
              updateValue={(value) => handleFormDataChange('codice_sdi', value)}
              errors={errors?.codice_sdi}
            />
            <TextInput
              id="email_pec"
              name="email_pec"
              placeholder={t('register.email_pec_no_required')}
              value={formData.email_pec}
              updateValue={(value) => handleFormDataChange('email_pec', value)}
              errors={errors?.email_pec}
            />
          </>
          )}
          <TextInput
            id="address"
            name="address"
            placeholder={t('indirizzo_required')}
            value={formData.address}
            updateValue={(value) => handleFormDataChange('address', value)}
            errors={errors?.address}
            forwardedRef={ref}
          />
          <TextInput
            id="city"
            name="city"
            placeholder={t('city_required')}
            value={formData.city}
            updateValue={(value) => handleFormDataChange('city', value)}
            errors={errors?.city}
          />
          <TextInput
            id="province"
            name="province"
            placeholder={t('province_required')}
            value={formData.province}
            updateValue={(value) => handleFormDataChange('province', value)}
            errors={errors?.province}
          />
          <TextInput
            id="postal_code"
            name="postal_code"
            placeholder={t('cap_required')}
            value={formData.postal_code}
            updateValue={(value) => handleFormDataChange('postal_code', value)}
            errors={errors?.postal_code}
          />
          <SelectInput
            id="country"
            name="country"
            value={formData.country} 
            updateValue={(value) => handleFormDataChange('country', value)}
            options={countryOptions}
            errors={errors?.village}
          />
        </>
      )}
      
    </div>
  );
};

export default CompanyInformation;
