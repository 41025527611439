import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';


import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../constants/ActionTypes';
import { getCertificateName } from '../../constants/certificates';
import { usePaymentByCode } from '../../hooks/usePaymentByCode';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';
import { loadProductNewTotals } from '../../hooks/useProductTotals';
import { useModal } from '../../providers/ModalProvider';
import { cartPaths } from '../../providers/TranslatedSlugProvider';
import { isCustomProduct } from '../../utils/customProduct';
import { handleMultiCartTypeError } from '../../utils/errorHandling';
import { formatPriceForView, formatQuantityForView } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import User from '../../utils/user';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton';
import CertificateIcon from '../product/Components/CertificateIcon/CertificateIcon';
import { CUSTOM_PRODUCT_IMAGE_SRC } from '../product/custom_product';

import DocumentsList from './components/DocumentsList/DocumentsList';
import { getProductsDetails, reorderProducts } from './components/OrderList/utils';

const OrderDetail = (lodemore) => {
  const { t } = useTranslation();
  const params = useParams();
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const [orderTracking, setOrderTracking] = useState([]);
  const [orderDetail, serOrderDetail] = useState([]);
  const [SalesTable, setSalesTable] = useState({});
  const [product, setProducts] = useState([]);
  const [supplementi, Setsupplementi] = useState(0);
  const [shipping, Setshipping] = useState(0);
  const [currency, setCurrency] = useState('$');
  const [DocuRefLine, setDocuRefLine] = useState('');
  const [price, SetPrice] = useState(0);
  const [suppliment, Setsuppliment] = useState(0);
  const loader = useLoader({ height: 546 });
  const dispatch = useDispatch();
  const { paymentMethods, paymentTerms } = usePaymentMethods({});
  const { payment: orderPayment, paymentTerm: orderPaymentTerm } = usePaymentByCode({
    paymentMethods,
    paymentCode: SalesTable.PaymMode,
    paymentTerms,
    paymentTermCode: SalesTable.Payment,
  });
  let totalIva;
  const localFormatPriceForView = (amount) => {
    return formatPriceForView(amount, User.getCurrency(dataUser));
  };

  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const getuserDataOrderDetails = () => {
    loader.watchPromise(
      axios
        .post(
          '/v1/order-detail',
          {
            customerid: dataUser.ACCOUNTNUM,
            orderid: params.id,
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser?.token}`,
            },
          }
        )
        .then((res) => {
          setOrderTracking(res?.data?.result?.data['orderTracking']);
          serOrderDetail(res?.data?.result?.data['SalesTable']);
          setSalesTable(res?.data?.result?.data['SalesTable'][0]);
          getproducts(res?.data?.result?.data['SalesTable']);
        })
        .catch((error) => console.log('error', error))
    );
  };

  const getIVA = (array, column) => {
    if (!array?.length) {
      return 0;
    }
    let values = array.map((item) => (item[column] == 'C-NAZ' ? 22 : 0));
    return values.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  };

  const getTotale = (array) => {
    let totale = 0;
    if (!array?.length) {
      return totale;
    }
    let values = array
      .filter((v) => v['SalesLine'])
      .map((item) => {
        return item['SalesLine'].map((val) => {
          totale = totale + parseFloat(val['SalesQty']) * parseFloat(val['SalesPrice']);
        });
      });
    return totale;
  };

  const getSpedizione = (array) => {
    let totale = 0;
    array.map((item) => {
      return item['SalesLine'].map((val) => {
        totale = totale + (JSON.stringify(val.MarkupTransLine) == 'null' ? 0 : 1);
      });
    });
    return totale;
  };

  const getproducts = async (array) => {
    array?.map((data) => {
      //console.log('data', data);
    });

    let supplementisun = 0;
    array?.map((data) => {
      setCurrency(data.CurrencyCode == 'USD' ? '$' : '€');

      if (data?.MarkupTransHeader?.length >= 1 && data?.MarkupTransHeader[0].MarkupCode == 'TRASP') {
        Setshipping(shipping + parseFloat(data?.MarkupTransHeader[0].Value));
      }

      data?.SalesLine?.map((val) => {
        setDocuRefLine(val.DocuRefLine);
        if (val?.MarkupTransLine?.length >= 1 && val?.MarkupTransLine[0].MarkupCode != 'TRASP') {
          supplementisun = parseFloat(val?.MarkupTransLine[0].Value) + supplementisun;
          Setsupplementi(supplementisun);
        }
      });
    });

    const products = await getProductsDetails(array);
    setProducts(products);
  };

  const availabilitysum = (items, prop) => {
    return items.reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
  };

  const modal = useModal();

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    getuserDataOrderDetails();
  }, []);

  if(parseInt(getIVA(orderDetail, 'TaxGroup')) >=1){
    totalIva = ((getTotale(orderDetail) + parseFloat(shipping) + supplementi) * 22) / 100
  }
  else{
    totalIva = parseFloat(0);
  }
  //console.log('orderDetail', orderDetail);
  //console.log('totalIva', totalIva);
  /*const totalIva = ((getTotale(orderDetail) + parseFloat(shipping) + supplementi) * 22) / 100;*/

  const InventDimIdToShowDigitalProductPassportFor = ['OC24-00237+0002992398', 'OC24-00248+0002992398'];
  const InventDimIdToShowTCFor = ['OC24-00245+0003683556', 'OC24-00248+0003683556'];

  return (
    <>
      <div className="main_content" style={{ paddingBottom: 100 }}>
        <section className="order_detailss">
          <div className="container">
            <div className="order_number_main">
              <div className="order_number_detail">
                <p>
                  {t('Ordine')}
                  {SalesTable?.SalesId ? <span>#{SalesTable?.SalesId}</span> : null}
                </p>
              </div>
              <div className="order_btn">
                <Link to="/dashboard-list">
                  <SecondaryButton>{t('Torna alla lista ordini')}</SecondaryButton>
                </Link>
                {SalesTable?.SalesLine?.length > 0 ? (
                  <PrimaryButton onClick={(e) => reorderProducts(modal, t, dispatch, dataUser, SalesTable, product)}>
                    {t('Riordina')}
                  </PrimaryButton>
                ) : null}
                {(params.status.toLowerCase() === 'fatturato' || params.status.toLowerCase() === 'consegnato') && dataUser?.create_ticket ? (
                  <div className="return_btn">
                    <Link to={'/ticket-create/' + dataUser.id + '/' + params.id}>{t('Apri Ticket')}</Link>
                  </div>
                ) : null}
              </div>
            </div>

            {loader.asJsx ||
              (OrderDetail ? (
                <>
                  <div className="order_pickup_detail">
                    <div className="order_address">
                      <p>
                        {t('Data creazione')}:{' '}
                        <span>
                          <Moment date={SalesTable.BFSalesDate} format="DD/MM/YYYY" />
                        </span>
                      </p>
                      <p>
                        {t('Utente')}: <span>master</span>
                      </p>
                      <p className="conform_state">
                        {t('Stato')}: <span>{t(params.status)}</span>
                      </p>
                    </div>
                  </div>
                  {orderDetail?.map((data, key) => {
                    return (
                      <div className="item_availability_section" key={key}>
                        <div className="item_availability_heading">
                          <h3>{t('Articoli')}</h3>
                        </div>
                        {data?.SalesLine?.map((val, i) => {
                          return (
                            <div className="order_available_item" key={i}>
                              <div className="item_availability_detail">
                                <div className="available_img">
                                  {isCustomProduct(dataUser, val.InventDim[0].ConfigId) ? (
                                    <img
                                      className="colorSchema"
                                      style={{ objectFit: 'cover' }}
                                      src={CUSTOM_PRODUCT_IMAGE_SRC}
                                    />
                                  ) : product[i]?.color?.hex_image ? (
                                    <img className="colorSchema" src={product[i]?.color.hex_image_url} />
                                  ) : (
                                    <div
                                      className="colorSchema"
                                      style={{
                                        backgroundColor: `#${product[i]?.color?.hex?.replace('#', '')}`,
                                      }}
                                    ></div>
                                  )}
                                </div>
                                <div className="availab_item">
                                  <div className="availab_item_name">
                                    <p className="article_name">
                                      <strong>{product[i]?.nome_articolo || val?.InventDim[0].ConfigId} </strong>
                                    </p>
                                    <p>
                                      <span className="order_name">{product[i]?.composition || val?.Name}</span>
                                      {product[i]?.nm ? (
                                        <span className="order_num">
                                          {' '}
                                          | Nm {product[i]?.nm} | Ne {product[i]?.ne} | Finezza{product[i]?.finezza}{' '}
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </p>
                                    {val?.YarntypeId_CGK_7262 ? (
                                      <>
                                        <p className="article_name">
                                          <strong>{t('Certificazioni')}</strong>
                                        </p>
                                        <p className="certificate">
                                          {getCertificateName(val?.YarntypeId_CGK_7262)}
                                          <CertificateIcon YARNTYPEID={val?.YarntypeId_CGK_7262} />
                                        </p>
                                      </>
                                    ) : null}
                                    {val?.ShippingDateConfirmed ? (
                                      <>
                                        <p className="article_name">
                                          <strong>{t('Data di consegna')}</strong>
                                        </p>
                                        <p>
                                          <Moment date={val?.ShippingDateConfirmed} format="DD/MM/YYYY" />
                                        </p>
                                      </>
                                    ) : null}
                                    {InventDimIdToShowDigitalProductPassportFor.includes(
                                      SalesTable?.SalesId + '+' + val?.InventDimId
                                    ) ? (
                                      <>
                                        <p className="article_name">
                                          <a
                                            className="download_pdf"
                                            target="_blank"
                                            href="https://app.sustainablebrandplatform.com/Mercerized.html"
                                          >
                                            digital product passport
                                          </a>
                                        </p>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="availab_item_detail availab_item_color">
                                    <p>
                                      <strong>{t('color')}:</strong> {val?.InventDim[0].InventColorId}
                                    </p>
                                  </div>
                                  {/*<div className="availab_item_detail availab_item_certificazione">*/}
                                  {/*  /!* <p><strong>Certificazione: </strong>Biofil - 30,00 {currency} </p> *!/*/}
                                  {/*</div>*/}
                                  {/*<div className="availab_item_detail availab_item_availability">*/}
                                  {/*  <p>/!* <strong>Disponibilità:</strong>Immediata - bagno unico *!/</p>*/}
                                  {/*</div>*/}
                                  <div className="availab_item_detail availab_item_price">
                                    <p>
                                      <strong>{t('Prezzo un.')}</strong> {localFormatPriceForView(val.SalesPrice)}
                                    </p>
                                  </div>
                                  {['stock service', 'on demand', 'leanh100'].includes(
                                    product[i]?.servizio?.toLowerCase()
                                  ) ? (
                                    <div className="availab_item_detail availab_item_price">
                                      <p>
                                        <strong>{t('Disponibilità')}</strong>
                                        {t('entro')}{' '}
                                        {product[i]?.servizio.toLowerCase() === 'stock service' ? '5gg' : null}
                                        {product[i]?.servizio.toLowerCase() === 'on demand' ? '4sett' : null}
                                        {product[i]?.servizio.toLowerCase() === 'leanh100' ? '15gg' : null}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div className="availab_item_detail availab_item_quantity">
                                    <p>
                                      <strong>{t('Quantità')}</strong>
                                      {formatQuantityForView(val.SalesQty)}
                                    </p>
                                  </div>
                                  <div className="availab_item_detail availab_item_quantity_to_be_sent">
                                    <p>
                                      <strong>{t('Qtà spedita')}</strong>
                                      {val.DeliveredIntotalSpecified ? formatQuantityForView(val.DeliveredIntotal) : 0}
                                    </p>
                                  </div>
                                  <div className="availab_item_detail availab_item_total">
                                    <p>
                                      <strong>{t('Totale')}</strong>
                                      {localFormatPriceForView(
                                        (val?.MarkupTransLine?.length >= 1 &&
                                        val?.MarkupTransLine[0].MarkupCode != 'TRASP'
                                          ? val?.MarkupTransLine[0].Value
                                          : 0) +
                                          val.SalesQty * val.SalesPrice
                                      )}
                                    </p>
                                  </div>
                                  {InventDimIdToShowTCFor.includes(SalesTable?.SalesId + '+' + val?.InventDimId) ? (
                                    <div className="availab_item_detail availab_item_tc">
                                      <p>
                                        <strong>{t('TC')}</strong>
                                        <a className="download_pdf" download href="/files/esempio TC.pdf">
                                          Download
                                        </a>
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}

                  <div className="order_total_section">
                    <div className="order_total">
                      <div className="order_total_item">
                        <p>{t('Totale articoli')}</p>
                        <p>{localFormatPriceForView(getTotale(orderDetail))}</p>
                      </div>

                      {supplementi != 0 ? (
                        <div className="order_total_item">
                          <p>{t('Totale supplementi')}</p>
                          <p>{localFormatPriceForView(supplementi)}</p>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="order_total_item">
                        <p>{t('Sub-totale')}</p>
                        <p>{localFormatPriceForView(parseFloat(getTotale(orderDetail)) + parseFloat(supplementi))}</p>
                      </div>

                      {shipping != 0 ? (
                        <div className="order_total_item">
                          <p>{t('Spese di spedizione')}</p>
                          <p>{localFormatPriceForView(shipping)}</p>
                          <p className="variation">{t('Possono essere soggette a variazioni')}</p>
                        </div>
                      ) : null}

                      {parseInt(getIVA(orderDetail, 'TaxGroup')) >= 1 ? (
                        <div className="order_total_item">
                          <p>Iva 22%</p>
                          <p>{localFormatPriceForView(totalIva)}</p>
                        </div>
                      ) : (
                        ''
                      )}
                      
                      <div className="order_total_item final_total">
                        <p>{t('Totale')}</p>
                        <p>
                          {localFormatPriceForView(
                            parseFloat(getTotale(orderDetail)) +
                              parseFloat(shipping) +
                              parseFloat(supplementi) +
                              totalIva
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="order_error_message">{t('order_could_not_be_loaded')}</div>
              ))}

            <div className="shipping_data">
              <div className="item_availability_heading">
                <h3>{t('Dati di spedizione')}</h3>
              </div>
              <div className="shipping_item_detail">
                <div className="business_detail">
                  <div className="business_detail_flex">
                    <p>
                      {t('Ragione sociale')} <strong>{dataUser?.business_name}</strong>
                    </p>
                  </div>
                  <div className="business_detail_flex">
                    <p>
                      {t('P.IVA')} <strong>{dataUser?.vat_number}</strong>
                    </p>
                  </div>
                  <div className="business_detail_flex">
                    <p>
                      {t('Email')} <strong>{dataUser?.email}</strong>
                    </p>
                  </div>
                  <div className="business_detail_flex">
                    <p>
                      {t('Telefono')} <strong>{dataUser?.mobile_no}</strong>
                    </p>
                  </div>
                </div>
                <div className="business_detail_flex">
                  <p>
                    {t('Indirizzo')} <strong>{orderDetail[0]?.TableDlvAddr?.[0]?.Address}</strong>
                  </p>
                </div>
                {/* <div className="shipping_details">
          <div className="multipal_shipping shipping_method">
            <div className="business_detail_flex multipal_Spedizione">
              <p>
                Spedizione <strong>Spedizione multipla</strong>
              </p>
            </div>
            <div className="business_detail_flex">
              <p>
                {" "}
                <strong>Spedizione 1</strong>6 articoli - data di consegna
                04/10/2022
              </p>
            </div>
            <div className="business_detail_flex">
              <p>
                {" "}
                <strong>Spedizione 2 </strong>1 articolo - data di consegna
                15/10/2022
              </p>
            </div>
            <div className="business_detail_flex">
              <p>
                {" "}
                <strong>Spedizione 3 </strong>3 articoli - data di consegna
                30/10/2022
              </p>
            </div>
          </div>
          <div className="shipping_method vettore_method">
            <div className="business_detail_flex">
              <p>
                Metodo di spedizione <strong>Vettore Filmar</strong>
              </p>
            </div>
          </div>
        </div> */}
              </div>
            </div>

            {orderTracking.length > 0 ? (
              <div className="shipping_data payment_method">
                <div className="item_availability_heading">
                  <h3>{t('Tracciamento')}</h3>
                </div>
                <div className="shipping_item_detail">
                  <div className="business_detail_flex">
                    {orderTracking.map((o) => (
                      <div className="tracking">
                        <p>
                          {t('Nome del corriere')}: <strong>{o.carrier_name}</strong>
                        </p>
                        <p>
                          {t('Numero di tracciamento')}: <strong>{o.tracking_number}</strong>
                        </p>
                        <p className="data">
                          {t('date')}: <strong>{o.date ? <Moment format="D MMM YYYY">{o.date}</Moment> : '-'}</strong>
                        </p>
                        {o.url ? (
                          <p className="flex">
                            <strong>
                              <a className="black" href={o.url}>
                                {t('tracking_link')}
                              </a>
                            </strong>
                          </p>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {orderPayment ? (
              <div className="shipping_data payment_method">
                <div className="item_availability_heading">
                  <h3>{t('Metodo di pagamento')}</h3>
                </div>
                <div className="shipping_item_detail">
                  <div className="business_detail_flex">
                    <strong>
                      {getTranslated(i18n, orderPayment, { en: 'name_en', it: 'name_it ' }, 'name_it')} -{' '}
                      {getTranslated(i18n, orderPaymentTerm, { en: 'name_en', it: 'name_it ' }, 'name_it')}
                    </strong>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="order-documents">
              <DocumentsList withFilters={false} orderId={params.id} />
            </div>

            {DocuRefLine ? (
              <div className="shipping_data payment_method order_note">
                <div className="item_availability_heading">
                  <h3>Nota d’ordine</h3>
                </div>
                <div className="shipping_item_detail ">
                  <div className="business_detail_flex">
                    <p>{DocuRefLine}</p>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {/* <div className="shipping_data payment_method">
      <div className="item_availability_heading">
        <h3>Fatture correlate</h3>
      </div>

       <div className="shipping_item_detail dx-1">
        <div className="business_detail_flex">
        <p className='w-75'>
            <strong>Fattura N°0000-AA-0000000</strong>
          </p>
          <p className=" w-25 download_pdf">
      <strong>Download</strong>{" "}
      <a href="#">
        <img className=" download_item" src="images/order/download.png" />
      </a>
    </p>
        </div>
      </div>

      <div className="shipping_item_detail dx-1">
        <div className="business_detail_flex">
          <p className='w-75'>
            <strong>Fattura N°0000-AA-0000000</strong>
          </p>
          <p className="w-25 download_pdf"><strong>Download</strong>{" "}

      <a href="#">
        <img className=" download_item" src="images/order/download.png" />
      </a>
    </p>
        </div>
      </div>

      <div className="shipping_item_detail dx-1">
        <div className="business_detail_flex">
        <p className='w-75'>
            <strong>Fattura N°0000-AA-0000000</strong>
          </p>
          <p className=" w-25 download_pdf">
      <strong>Download</strong>{" "}
      <a href="#">
        <img className="w-25 download_item" src="images/order/download.png" />
      </a>
    </p>
        </div>
      </div>    
     
     
      </div> */}
          </div>
        </section>
      </div>
    </>
  );
};
export default OrderDetail;