import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getTranslated } from '../../../utils/translations';
import SelectInput from '../../Inputs/SelectInput';
import TextInput from '../../Inputs/TextInput';

const AdditionalInformation = ({ className, formDetails, formData, handleFormDataChange, errors }) => {
  const { t, i18n } = useTranslation();
  const handleCheckboxChange = (e) => {
    handleFormDataChange('subscribe_newsletter', e.target.checked);
  };
  const handleProductionForChange = (value) => {
    const [productionFor, productionForText] = value.split(' ');
    handleFormDataChange('production_for', productionFor);
    if (productionFor === 'own_brand') {
      handleFormDataChange('production_for_own_brand', productionForText);
    } else if (productionFor === 'specify') {
      handleFormDataChange('production_for_specify', productionForText);
    }    
  };
  return (
    <div className={className}>
      <SelectInput
        id="revenue"
        name="revenue"
        label={t('register.revenue')}
        value={formData.revenue}
        updateValue={(value) => handleFormDataChange('revenue', value)}
        options={(formDetails?.revenue || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.revenue}
      />
      <TextInput
        id="number_of_machines"
        name="number_of_machines"
        placeholder={t('register.number-of-machines')}
        value={formData.number_of_machines}
        updateValue={(value) => handleFormDataChange('number_of_machines', value)}
        errors={errors?.number_of_machines}
      />
      <SelectInput
        id="number_of_employees"
        name="number_of_employees"
        label={t('register.number-of-employees')}
        value={formData.number_of_employees}
        updateValue={(value) => handleFormDataChange('number_of_employees', value)}
        options={(formDetails?.number_of_employees || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.number_of_employees}
      />
      <SelectInput
        id="production_for"
        name="production_for"
        label={t('register.production-for')}
        value={formData.production_for.split(' ')[0]}
        updateValue={handleProductionForChange}
        options={(formDetails?.production_for || []).map((r) => ({
          value: r.key,
          label: getTranslated(i18n, r, { en: 'name_en', it: 'name_it ' }, 'name_it'),
        }))}
        errors={errors?.production_for}
      />
      <div>
        <label style={{ display: 'flex', alignItems: 'baseline' }}>
          <input
            type="checkbox"
            name="subscribe_newsletter"
            checked={formData.subscribe_newsletter || false}
            onChange={handleCheckboxChange}
          />
          <p style={{ marginLeft: '10px' }}>{t('register.form-register-subscribe-newsletter')}</p>
        </label>
      </div>
    </div>
  );
};

export default AdditionalInformation;
