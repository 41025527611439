import React, { useState, useEffect } from 'react';
import TextInput from '../TextInput';
import ValidationError from '../ValidationError';
import './style.scss';

const SelectInput = ({ id, name, label, value, updateValue, options, errors = [] }) => {
  const OTHER_VALUE_TRIGGER = 'specify';
  const OWN_BRAND_TRIGGER = 'own_brand';

  const [isOtherValue, setIsOtherValue] = useState(false);
  const [isOwnBrand, setIsOwnBrand] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setIsOtherValue(selectedValue === OTHER_VALUE_TRIGGER);
    setIsOwnBrand(selectedValue === OWN_BRAND_TRIGGER);
    if (selectedValue === OTHER_VALUE_TRIGGER || selectedValue === OWN_BRAND_TRIGGER) {
      updateValue(`${selectedValue} ${inputValue}`);
    } else {
      updateValue(selectedValue);
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    if (isOtherValue) {
      updateValue(`${OTHER_VALUE_TRIGGER} ${value}`);
    } else if (isOwnBrand) {
      updateValue(`${OWN_BRAND_TRIGGER} ${value}`);
    } else {
      updateValue(value);
    }
  };

  useEffect(() => {
    if (value.startsWith(OTHER_VALUE_TRIGGER)) {
      setIsOtherValue(true);
      setInputValue('');
    } else if (value.startsWith(OWN_BRAND_TRIGGER)) {
      setIsOwnBrand(true);
      setInputValue('');
    }

  }, [value]);

  return (
    <div className="select-input">
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select id={id} value={isOtherValue ? OTHER_VALUE_TRIGGER : isOwnBrand ? OWN_BRAND_TRIGGER : value} onChange={handleSelectChange}>
        {options.map((o) => (
          <option key={o.value} value={o.value}>{o.label}</option>
        ))}
      </select>
      {(isOtherValue || isOwnBrand) && (
        <TextInput
          id={`${id}_${isOtherValue ? 'specify' : 'own_brand'}`}
          name={`${name}_${isOtherValue ? 'specify' : 'own_brand'}`}
          updateValue={handleInputChange}
          value={inputValue}
        />
      )}
      {errors.length ? <ValidationError errorMessage={errors[0]} /> : null}
    </div>
  );
};

export default SelectInput;