import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultCountries } from 'react-international-phone';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import classnames from 'classnames';

import { useRegistrationFormDetails } from '../../../hooks/useRegistrationFormDetails';
import { useModal } from '../../../providers/ModalProvider';
import { useLoader } from '../../../utils/hooks';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton/SecondaryButton';
import ValidationError from '../../Inputs/ValidationError';
import Loader from '../../Loader';
import AuthFormHeader from '../AuthFormHeader';
import AuthFormWrapper from '../AuthFormWrapper';

import AdditionalInformation from './AdditionalInformation';
import CompanyInformation from './CompanyInformation';
import RegistrationDetails from './RegistrationDetails';

import './styles.scss';

const RegisterForm = () => {
  const navigate = useNavigate();
  const modal = useModal();
  const { t, i18n } = useTranslation();

  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');
  const loader = useLoader({ paddingBottom: 24 }, false, 1);

  const { details: formDetails, isLoading: isLoadingDetails } = useRegistrationFormDetails();

  const FormTitles = [t('register.step-1'), t('register.step-2'), t('register.step-3')];

  const [formData, setFormData] = useState({
    business_name: '',
    alreadyClient: '0',
    selecttypeClient: '0',
    codice_fiscale: '',
    code: '',
    vat_number: '',
    country: 'Italy',
    address: '',
    city: '',
    postal_code: '',
    province: '',
    country_code: 'IT',
    mobile_no: '',
    email: '',
    email_company: '',
    email_personal: '',
    email_pec: '',
    reason_for_registration: '',
    role_in_the_company: 'management',
    customer_type: '',
    production_type: '',
    production_for: '',
    production_for_own_brand: '',
    production_for_specify: '',
    revenue: '',
    number_of_machines: '',
    number_of_employees: '',
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      reason_for_registration: formDetails?.reason_for_registration ? formDetails?.reason_for_registration[0]?.key : '',
      role_in_the_company: formDetails?.role_in_the_company ? formDetails?.role_in_the_company[0]?.key : '',
      customer_type: formDetails?.customer_type ? formDetails?.customer_type[0]?.key : '',
      production_type: formDetails?.production_type ? formDetails?.production_type[0]?.key : '',
      revenue: formDetails?.revenue ? formDetails?.revenue[0]?.key : '',
      number_of_employees: formDetails?.number_of_employees ? formDetails?.number_of_employees[0]?.key : '',
      production_for: formDetails?.production_for ? formDetails?.production_for[0]?.key : '',
    }));
  }, [formDetails]);

  useEffect(() => {
    const fieldsInTabs = [
      [
        'business_name',
        'alreadyClient',
        'code',
        'vat_number',
        'address',
        'city',
        'postal_code',
        'province',
        'country',
        'country3',
        'email',
        'email_company',
        'email_personal',
        'codice_fiscale',
        'email_pec',
        'mobile_no',
        'country_code',
        'reason_for_registration',
      ],
      ['role_in_the_company','customer_type', 'production_type'],
      ['revenue', 'number_of_machines', 'number_of_employees', 'production_for','production_for_own_brand','production_for_specify'],
    ];

    for (let tabIndex = 0; tabIndex < fieldsInTabs.length; tabIndex++) {
      const fields = fieldsInTabs[tabIndex];
      for (const field of fields) {
        if (errors[field]) {
          setPage(tabIndex);
          return;
        }
      }
    }
  }, [errors]);

  const handleFormDataChange = (name, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setError('');

    loader.watchPromise(
      axios({
        method: 'post',
        url: '/v1/signup',
        data: {
          ...formData,
          village: formData.country,
          is_status: '1',
        },
      })
        .then(function (response) {
          modal.showSuccess({ subtitle: t('register.success-message') });
          localStorage.setItem('token', response.data.result.token);
          navigate('/');
        })
        .catch(function (err) {
          setError(err?.response?.data?.message || '');
          setErrors(err?.response?.data?.result?.errors || {});
        })
    );
  };

  const handlePlaceSelected = (places) => {
    const route = places?.address_components?.find((a) => a?.types?.includes('route'));
    const street_number = places?.address_components?.find((a) => a?.types?.includes('street_number'));
    const street_address = places?.address_components?.find((a) => a?.types?.includes('street_address'));
    handleFormDataChange(
      'address',
      `${route?.short_name ?? ''} ${street_number?.short_name ?? ''} ${street_address?.short_name ?? ''}`.replace(
        /\s+/g,
        ' '
      )
    );

    const locality = places?.address_components?.find((a) => a?.types?.includes('locality'));
    handleFormDataChange('city', `${locality?.short_name ?? ''}`);

    const postal_code = places?.address_components?.find((a) => a?.types?.includes('postal_code'));
    handleFormDataChange('postal_code', `${postal_code?.short_name ?? ''}`);

    const administrative_area_level_2 = places?.address_components?.find((a) =>
      a?.types?.includes('administrative_area_level_2')
    );
    handleFormDataChange('province', `${administrative_area_level_2?.short_name ?? ''}`);

    const country = places?.address_components?.find((a) => a?.types?.includes('country'));
    const c = defaultCountries.find((c) => c[1]?.toUpperCase() === country?.short_name?.toUpperCase());
    if (c) {
      handleFormDataChange('country', c[0]);
      handleFormDataChange('country_code', c[1].toUpperCase());
    }
  };

  const isFirstPage = useMemo(() => page === 0, [page]);
  const isLastPage = useMemo(() => page === FormTitles.length - 1, [page]);
  const { verified, setVerified } = useState();

  const scrollToElementWithError = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  };

  const isValidCodiceFiscale = (codice_fiscale) => {
    const regex = /^[A-Z]{6}[A-Z0-9]{2}[A-Z][A-Z0-9]{2}[A-Z][A-Z0-9]{3}[A-Z]$/;
    return regex.test(codice_fiscale);
  };


  const handleNextPageClick = async () => {
    const selecttypeClient = document.getElementById('selecttypeClient')?.value;
    const nameRegister = document.querySelector('input[name="name_register"]')?.value;
    const surname_register = document.querySelector('input[name="surname_register"]')?.value;
    const email_personal = document.querySelector('input[name="email_personal"]')?.value;
    const email_company = document.querySelector('input[name="email_company"]')?.value;
    const codice_fiscale = document.querySelector('input[name="codice_fiscale"]')?.value;
    const mobile_no = document.querySelector('input[name="mobile_no"]')?.value; 
    //const code = document.querySelector('input[name="code"]')?.value;
    const vat_number = document.querySelector('input[name="vat_number"]')?.value;
    const business_name = document.querySelector('input[name="business_name"]')?.value;
    const address = document.querySelector('input[name="address"]')?.value;
    const city = document.querySelector('input[name="city"]')?.value;
    const province = document.querySelector('input[name="province"]')?.value;
    const postal_code = document.querySelector('input[name="postal_code"]')?.value;
    //const country = document.querySelector('input[name="country"]')?.value;

    //Step 2
    const customer_type = document.querySelector('select[name="customer_type"]')?.value;
    const production_type = document.querySelector('select[name="production_type"]')?.value;
    const revenue = document.querySelector('select[name="revenue"]')?.value;
    const number_of_machines = document.querySelector('input[name="number_of_machines"]')?.value;
    const number_of_employees = document.querySelector('select[name="number_of_employees"]')?.value;
    const production_for = document.querySelector('select[name="production_for"]')?.value;
    const alreadyClient = document.querySelector('input[name="alreadyClient"]')?.value;
    

    /*if (isFirstPage && formData.alreadyClient === '1' ){
      setIsLoadingNextPage(true);
      setErrors({});
      setIsLoadingNextPage(false);
    }*/
    if(isFirstPage){
           
      //Campi comuni ad entrambi
      if (!nameRegister) {
        setErrors({ name_register: [t('register.name_register_required')] });
        scrollToElementWithError('name_register');
        setIsLoadingNextPage(false);
        return;
      }
      if(!surname_register){
        setErrors({ surname_register: [t('register.surname_register_required')] });
        scrollToElementWithError('surname_register');
        setIsLoadingNextPage(false);
        return;
      }
      

      if(selecttypeClient === '0'){ //Azienda

        if(!email_company ){
          setErrors({ email_company: [t('register.email_company_required')] });
          scrollToElementWithError('email_company');
          setIsLoadingNextPage(false);
          return;
        }
        if(!business_name){
          setErrors({ business_name: [t('register.business_name_required')] });
          scrollToElementWithError('business_name');
          setIsLoadingNextPage(false);
          return;
        }
        if(!vat_number){
          setErrors({ vat_number: [t('register.vat_number_required')] });
          scrollToElementWithError('vat_number');
          setIsLoadingNextPage(false);
          return;
        }
      }
      else if(selecttypeClient === '1'){ //Privato

        if(!email_personal){
          setErrors({ email_personal: [t('register.email_personal_required')] });
          scrollToElementWithError('email_personal');
          setIsLoadingNextPage(false);
          return;
        }
        if (!codice_fiscale) {
          setErrors({ codice_fiscale: [t('register.codice_fiscale_field_required')] });
          scrollToElementWithError('codice_fiscale');
          setIsLoadingNextPage(false);
          return;
        }
       
        if(!isValidCodiceFiscale(codice_fiscale) || codice_fiscale.length < 16){
          setErrors({ codice_fiscale: [t('register.codice_fiscale_invalid')] });
          scrollToElementWithError('codice_fiscale');
          setIsLoadingNextPage(false);
          return;
        }
      }
      if(!mobile_no || mobile_no =='+39 '){
        setErrors({ mobile_no: [t('register.mobile_no_field_required')] });
        scrollToElementWithError('mobile_no');
        setIsLoadingNextPage(false);
        return;
      }
      if(mobile_no.length < 10){
        setErrors({ mobile_no: [t('register.mobile_no_invalid')] });
        scrollToElementWithError('mobile_no');
        setIsLoadingNextPage(false);
        return;
      }

      if(!address){
        setErrors({ address: [t('register.indirizzo_filed_required')] });
        scrollToElementWithError('address');
        setIsLoadingNextPage(false);
        return;
      }
      if(!city){
        setErrors({ city: [t('register.city_field_required')] });
        scrollToElementWithError('city');
        setIsLoadingNextPage(false);
        return;
      }
      if(!province){
        setErrors({ province: [t('register.province_field_required')] });
        scrollToElementWithError('province');
        setIsLoadingNextPage(false);
        return;
      }
      if(!postal_code){
        setErrors({ postal_code: [t('register.postal_code_field_required')] });
        scrollToElementWithError('postal_code');
        setIsLoadingNextPage(false);
        return;
      }

    }
    //console.log('formData', formData);
    setErrors({});
    setPage((page) => (!isLastPage ? page + 1 : page)); 
  };
  const handlePrevPageClick = () => setPage((page) => (!isFirstPage ? page - 1 : page));

  return (
    <AuthFormWrapper className="register-form">
      <AuthFormHeader title={t('common.richiedi_accesso')} description={t('new_acc_heading')} />
      <div className="steps">
        <div className="progress-step-title">{FormTitles[page]}</div>
        <div className="progress-bar">
        <div className={classnames({ 'progress-step': true, active: page >= 0 })}></div>
        <div className={classnames({ 'progress-step': true, active: page >= 1 })}></div>
        <div className={classnames({ 'progress-step': true, active: page >= 2 })}></div>
        </div>
      </div>
      <form>
        <CompanyInformation
          className={classnames({ fields: true, active: page === 0 })}
          formDetails={formDetails}
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          handlePlaceSelected={handlePlaceSelected}
          errors={errors}
        />
        <RegistrationDetails
          className={classnames({ fields: true, active: page === 1 })}
          formDetails={formDetails}
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          errors={errors}
        />
        <AdditionalInformation
          className={classnames({ fields: true, active: page === 2 })}
          formDetails={formDetails}
          formData={formData}
          handleFormDataChange={handleFormDataChange}
          errors={errors}
        />
        {error ? (
          <div className="actions">
            <ValidationError errorMessage={error} />
          </div>
        ) : null}
        <div className="actions">
          
          {!isFirstPage ? (
            <SecondaryButton type="button" onClick={handlePrevPageClick}>
              {t('register.back')}
            </SecondaryButton>
          ) : null}
          <div className="right">
            {isLastPage ? (
              loader.asJsx || (
                <PrimaryButton type="button" onClick={handleSubmit}>
                {t('invia_richiesta')}
                </PrimaryButton>
              )
            ) : isLoadingNextPage ? (
              <Loader />
            ) : formData.alreadyClient === '0' || (formData.alreadyClient === '1' && formData.code!='' && formData.vat_number!='') ? (
              <PrimaryButton type="button" onClick={handleNextPageClick}>
                {t('register.next')}
              </PrimaryButton>
            ) : null}
          </div>
        </div>
      </form>
      <div className="back-to-login">
        <p>{t('common.registered_yet')}</p>
        <Link className="login" to="/login">
          {t('torna_al_login')}
        </Link>
      </div>
    </AuthFormWrapper>
  );
};

export default RegisterForm;
