import { React, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';

import { getCertificateName } from '../../constants/certificates';
import { usePaymentByCode } from '../../hooks/usePaymentByCode';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';
import { SAMPLE_PRODUCT_TYPE } from '../../hooks/useSampleProducts';
import { useModal } from '../../providers/ModalProvider';
import { cartPaths, orderPlacedPaths } from '../../providers/TranslatedSlugProvider';
import { formatPriceForView as localFormatPrice } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import User from '../../utils/user';
import CertificateIcon from '../product/Components/CertificateIcon/CertificateIcon';
import ProductImage from '../product/Components/ProductImage';
import { CUSTOM_PRODUCT_IMAGE_SRC } from '../product/custom_product';

import CheckoutPurchase from './components/CheckoutPurchase';
import PSample from './components/PSample';
//import state from 'sweetalert/typings/modules/state';


const Checkout = (props) => {
  const [totalForProducts, setTotalForProducts] = useState(0);
  const [totalSupplement, setTotalSupplement] = useState(0);
  const [details, Setdetails] = useState({});
  const [user, SetUser] = useState([]);
  const [SalesTable, SetSalesTable] = useState([]);
  const [address, Setaddress] = useState(0);
  const [FIL_LOGISTICSADDRESSZONEID, SetFIL_LOGISTICSADDRESSZONEID] = useState(0);
  const [Shipping_method, SetShipping_method] = useState(0);
  const [shipping_option, Setshipping_option] = useState('single');
  const [availableShippingOptionList, setAvailableShippingOptionList] = useState([{ type: 'single', idOnFrontEnd: 1 }]);
  const [shipping_, Setshipping] = useState(0);
  const [Payment_method, SetPayment_method] = useState(0);
  const [referent, setReferent] = useState('');
  const [notes, Setnote] = useState(0);
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);
  const { t, i18n } = useTranslation();
  const detailsLoader = useLoader({ height: '80vh' });
  const [userShipCarrierAccount, setUserShipCarrierAccount] = useState('');
  const [shipCarrierAccount, setShipCarrierAccount] = useState('');
  const [spedizione_esente, setSpedizione_esente] = useState('');
  const { paymentMethods, paymentTerms } = usePaymentMethods({});


  
  const { payment, paymentTerm } = usePaymentByCode({
    paymentMethods,
    paymentCode: dataUser.PAYMMODE,
    paymentTerms,
    paymentTermCode: dataUser.PAYMTERMID,
  });

  
  const addresses = details?.addresses || [];
  const formatPriceForView = (amount) => localFormatPrice(amount, User.getCurrency(dataUser));

  const hasAtLeastOneOnDemand = useMemo(() => {
    let result = false;
    if (details?.carts?.find) {
      result = !!details.carts.find((cart) => {
        let parsed = JSON.parse(cart?.is_available_json);
        return parsed?.available === 'on demand';
      });
    }
    return result;
  }, [details?.carts]);

  useEffect(() => {
    let isAlreadyAdded = !!availableShippingOptionList.find((i) => i.type === 'multiple');

    if (details?.carts?.length > 1 && hasAtLeastOneOnDemand && !isAlreadyAdded) {
      setAvailableShippingOptionList((s) => [...s, { type: 'multiple', idOnFrontEnd: 2 }]);
      Setshipping_option('single');
    }
  }, [hasAtLeastOneOnDemand, details?.carts]);
  //const userId = dataUser.parent_id ? dataUser.parent_id : dataUser.id
  const list = (id) => {
    detailsLoader.watchPromise(
      axios.get('/v1/checkout-page?client_id=' + dataUser.id+'&parent_id='+dataUser.parent_id+'').then((response) => {
        Setdetails(response.data.result.data);
        // TODO: this probably will be moved to back end on upcoming refactor
        setTotalSupplement(
          (response.data.result.data?.carts || []).reduce((acc, item) => {
            const parsedData = JSON.parse(item.is_available_json);
            const suppliment = Array.isArray(parsedData?.suppliment) ? parsedData.suppliment : [];
            const supplimentTotal = suppliment.reduce((sum, value) => sum + (value || 0), 0);
            return acc + supplimentTotal;
          }, 0)
        );
        setTotalForProducts(
          (response.data.result.data?.carts || []).reduce((acc, item) => {
            return (
              acc +
              ((parseFloat(item.product_price) + (JSON.parse(item.is_available_json)?.suppliment2 || 0)) *
                item.quantity || 0)
            );
          }, 0)
        );
      })
    );
  };
  const modal = useModal();

  const isSampleOrder = useMemo(() => {
    return details?.carts?.every((item) => item.product_type === SAMPLE_PRODUCT_TYPE);
  }, [details, SAMPLE_PRODUCT_TYPE]);

  const addTableDlvAddr = (tableName, data) => {
    data.append(`${tableName}[0][TableDlvAddr][0][Address]`, '');

    let selectedAddress = details.addresses.find((i) => i.id === address);

    if (selectedAddress) {
      data.append(`${tableName}[0][TableDlvAddr][0][Address]`, selectedAddress.ADDRESS);
      data.append(`${tableName}[0][TableDlvAddr][0][County]`, selectedAddress.COUNTY);
      data.append(`${tableName}[0][TableDlvAddr][0][LocationName]`, selectedAddress.ADDRESSNAME);
      data.append(`${tableName}[0][TableDlvAddr][0][Street]`, selectedAddress.STREET);
      data.append(`${tableName}[0][TableDlvAddr][0][ZipCode]`, selectedAddress.ZIPCODE);
      data.append(`${tableName}[0][TableDlvAddr][0][City]`, selectedAddress.CITY);
      data.append(`${tableName}[0][TableDlvAddr][0][CountryRegionId]`, selectedAddress.COUNTRYREGIONID);
      // data.append(`${tableName}[0][TableDlvAddr][0][Location]`, selectedAddress.LOCATIONID);
    }

    return data;
  };

  const addNotes = (tableName, data) => {
    if (notes) {
      data.append(`${tableName}[0][DocuRefHeader][0][Name]`, 'Nota ordine');
      data.append(`${tableName}[0][DocuRefHeader][0][Notes]`, notes);
      data.append(`${tableName}[0][DocuRefHeader][0][TypdeId]`, 'nota');
    }

    return data;
  };

  const buildSampleOrderBody = () => {
    let data = new FormData();
    const formattedDate = new Date().toISOString().slice(0, 16);
    data.append('FILSamplingOrder[0][FILSamplingAccountType]', 0);
    data.append('FILSamplingOrder[0][FILSamplingCausalCode]', 'WEB');
    data.append('FILSamplingOrder[0][FILSamplingCustomerRef]', referent);
    data.append('FILSamplingOrder[0][FILSamplingOrderDate]', formattedDate);
    data.append('FILSamplingOrder[0][FILSamplingOrderDateSpecified]', true);
    data = addTableDlvAddr('FILSamplingOrder', data);
    data = addNotes('FILSamplingOrder', data);

    details.carts.map((item, index) => {
      const is_available_json = JSON.parse(item.is_available_json);
      data.append(
        'FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][FILSamplingItemId]',
        is_available_json?.sample?.subProduct?.codice_sub_sample ?? is_available_json?.sample?.codice_sample
      );
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][FILSamplingLineNum]', 1);
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][FILSamplingLineNumSpecified]', true);
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][FILSamplingQty]', item.quantity);
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][FILSamplingQtySpecified]', true);
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][FILSamplingUnit]', 'kg');
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][InventDim][0][ConfigId]', item.config_id);
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][InventDim][0][InventColorId]', 'AM004');
      data.append('FILSamplingOrder[0][FILSamplingOrderTrans][' + index + '][InventDim][0][InventSiteId]', 'FILM');
    });

    return data;
  };

  const buildStandardOrderBody = () => {
    let data = new FormData();

    const formattedDate = new Date().toISOString().slice(0, 16);

    data.append('client_id', dataUser.id);
    data.append('customerid', dataUser.ACCOUNTNUM);
    data.append('SalesTableArray[0][CurrencyCode]', dataUser.CURRENCY);
    data.append('SalesTableArray[0][CustAccount]', dataUser.ACCOUNTNUM);
    data.append('SalesTableArray[0][DeliveryDate]', formattedDate);
    data.append('SalesTableArray[0][DeliveryDateSpecified]', true);
    data.append('SalesTableArray[0][DlvTerm]', dataUser.DLVTERM);
    data.append('SalesTableArray[0][FIL_OrderType]', 'WEB');
    data.append('SalesTableArray[0][PaymMode]', dataUser.PAYMMODE);
    data.append('SalesTableArray[0][MCROrderStoppedSpecified]', true);
    data.append('SalesTableArray[0][PurchOrderFormNum]', 'WEB');
    data.append('SalesTableArray[0][ReceiptDateRequested]', formattedDate);
    data.append('SalesTableArray[0][FIL_LogisticsAddressZoneId]', FIL_LOGISTICSADDRESSZONEID);
    data.append('SalesTableArray[0][SalesType]', 3);
    data.append('SalesTableArray[0][SalesTypeSpecified]', true);
    data.append('SalesTableArray[0][ShipCarrierAccount]', shipCarrierAccount);
    data = addTableDlvAddr('SalesTableArray', data);
    data = addNotes('SalesTableArray', data);

    if (agentDataDetail?.id) {
      data.append('SalesTableArray[0][SalesOriginId]', agentDataDetail?.ACCOUNTNUM === 'Filmar' ? 'Filmar' : 'Agente');
    }

    if (shipping_option === 'single') {
      data.append('SalesTableArray[0][PickComplete_CGK_2014Specified]', true);
      data.append('SalesTableArray[0][PickComplete_CGK_2014]', 'yes');
    }

    const result = details?.carts?.find((item) => parseFloat(item?.precise_integer) >= 1);
    if (result) {
      data.append('SalesTableArray[0][SalesOrderGroup_CGK_2014]', true);
      data.append('SalesTableArray[0][PreciseCones_CGK_2014]', 'yes');
    }

    //  [
    //   "Address" => 'same field of user_adresses table on db',
    //   "County" => "same field of user_adresses table on db",
    //   "LocationName" => "ADDRESSNAME field of user_adresses table on db"
    //   "Street" => "same field of user_adresses table on db"
    //   "ZipCode" =>"same field of user_adresses table on db"
    //   "City" => "same field of user_adresses table on db"
    //   "CountryRegionId" =>"same field of user_adresses table on db"
    //   "Location" => "same field of user_adresses table on db"
    // ]);

    // let preciseCones = 1;
    
    details?.carts?.map((item, index) => {
      const id = JSON.parse(item.is_available_json)?.ItemId;
      const suppliment = JSON.parse(item.is_available_json)?.suppliment;

      const gestione_spese_get = JSON.parse(item.is_available_json)?.gestione_spese;
      let gestione_spese_ready = 'GEST';

      //console.log('gestione_spese_get',gestione_spese_get);
      if(gestione_spese_get === 0 || gestione_spese_get === 2) {
        gestione_spese_ready = 'GEST';
      } else if(gestione_spese_get === 1) {
        gestione_spese_ready = 'GESTLH100';
      }
      //console.log('get certificate',getCertificateName());
      // if (parseFloat(item?.precise_integer) >= 1 && preciseCones) {
      //   preciseCones = 0;
      //   data.append('SalesTableArray[0][SalesOrderGroup_CGK_2014]', true);
      //   data.append('SalesTableArray[0][PreciseCones_CGK_2014]', 'yes');
      // }

      const number = user.find((o) => o.qty >= parseFloat(item.quantity))?.randomNumber || 3;
      data.append('SalesTableArray[0][SalesLine][' + index + '][ItemId]', id || 'FI30/100074S550407');
      data.append('SalesTableArray[0][SalesLine][' + index + '][LineNum]', index + 1);
      data.append('SalesTableArray[0][SalesLine][' + index + '][LineNumSpecified]', true);
      data.append(
        'SalesTableArray[0][SalesLine][' + index + '][SalesPrice]',
        parseFloat(item.product_price) + (JSON.parse(item.is_available_json)?.suppliment2 || 0)
      );
      data.append('SalesTableArray[0][SalesLine][' + index + '][SalesPriceSpecified]', true);
      data.append('SalesTableArray[0][SalesLine][' + index + '][SalesQty]', item.quantity);
      data.append('SalesTableArray[0][SalesLine][' + index + '][SalesUnit]', 'kg');

      if (item.certificate) {
        data.append('SalesTableArray[0][SalesLine][' + index + '][YarntypeId_CGK_7262]', item.certificate);
        data.append('SalesTableArray[0][SalesLine][' + index + '][TCRequest_CGK_7262]', '1');
        data.append(
          'SalesTableArray[0][SalesLine][' + index + '][SalesExtraPrice_CGK_23882]',
          JSON.parse(item.is_available_json)?.suppliment2 || 0
        );
        data.append('SalesTableArray[0][SalesLine][' + index + '][SalesExtraPrice_CGK_23882Specified]', true);
      }

      if (Number(dataUser.FILSALESWETNESSCALCEXCLUDE) !== 1) {
        data.append(
          'SalesTableArray[0][SalesLine][' + index + '][FILSalesWetnessPercent]',
          number == 3 ? 3 : number.toFixed(1) + `${parseInt(Math.random() * 10)}`
        );
        data.append('SalesTableArray[0][SalesLine][' + index + '][FILSalesWetnessPercentSpecified]', true);
      }

      data.append('SalesTableArray[0][SalesLine][' + index + '][InventDim][0][ConfigId]', item.config_id);
      data.append('SalesTableArray[0][SalesLine][' + index + '][InventDim][0][InventColorId]', item.color_id);
      data.append('SalesTableArray[0][SalesLine][' + index + '][InventDim][0][InventSiteId]', 'FILM');
      if (suppliment||0) {
       data.append('SalesTableArray[0][SalesLine]['+index+'][MarkupTransLine][0][Value]', suppliment);
       data.append('SalesTableArray[0][SalesLine]['+index+'][MarkupTransLine][0][ValueSpecified]', true);
       data.append('SalesTableArray[0][SalesLine]['+index+'][MarkupTransLine][0][LineNum]', index+1);
       data.append('SalesTableArray[0][SalesLine]['+index+'][MarkupTransLine][0][LineNumSpecified]', true);
       data.append('SalesTableArray[0][SalesLine]['+index+'][MarkupTransLine][0][MarkupCode]', gestione_spese_ready);
       data.append('SalesTableArray[0][SalesLine]['+index+'][MarkupTransLine][0][TaxItemGroup]', 'ART');
       }

      let DocuRefLine = [];
      if (parseFloat(item?.precise_integer) >= 1) {
        // DocuRefLine.push({
        //   name: 'Richiesta Rocche precise',
        //   notes: 'richiesto nr.' + item?.precise_integer,
        //   typdeId: 'nota',
        // });
      }

      const available = JSON.parse(item.is_available_json)?.available;
      if (available) {
        DocuRefLine.push({
          name: 'Tipo di disponibilità richiesta',
          notes: available,
          typdeId: 'nota',
        });
      }

      DocuRefLine.map((item, drli) => {
        data.append('SalesTableArray[0][SalesLine][' + index + '][DocuRefLine][' + drli + '][Name]', item.name);
        data.append('SalesTableArray[0][SalesLine][' + index + '][DocuRefLine][' + drli + '][Notes]', item.notes);
        data.append('SalesTableArray[0][SalesLine][' + index + '][DocuRefLine][' + drli + '][TypdeId]', item.typdeId);
      });
    });

    // Stampa il contenuto di FormData
    for (let pair of data.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
    }
    return data;
  };
 

  const Placeorder = () => {
    modal.showLoading({
      title: t('popup.orderConfirmationAwaitingTitle'), //'Placing Order...',
      subtitle: t('popup.orderConfirmationAwaitingSubtitle'), // 'Please wait while we process your order.',
      allowOutsideClick: false,
    });

    let data;
    
    if (isSampleOrder) {
      data = buildSampleOrderBody();
    } else {
      data = buildStandardOrderBody();
    }

    //console.log('data',data);
    /*axios
      .post(
      (isSampleOrder ? '/v1/order-sample-write-api?client_id=' : '/v1/order-write-api?client_id=') + dataUser.id,
      data,
      {
        headers: {
        Authorization: `Bearer ${dataUser?.token}`,
        },
      }
      )
      .then((response) => {
      function extractThirdWord(inputString) {
        const words = inputString.split(' ');

        if (words.length >= 3) {
        return words[2];
        }

        return '';
      }

      let href =
        getTranslated(i18n, orderPlacedPaths, { en: 'en', it: 'it' }, 'it') +
        '?id=' +
        extractThirdWord(response.data?.result?.data?.payload?.CDATA || '');

      if (isSampleOrder) {
        href += '&type=sample';
      }

      window.location.href = href;
    });*/
  };

  const Qtysum = (items, prop) => {
    return items.reduce(function (a, b) {
      return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
  };

  const handleShipping = () => {
    Setshipping(0);
    axios
      .post('/v1/product_detail/get-shipping', {
        CURRENCYCODE: dataUser?.CURRENCY,
        WEIGHT: Qtysum(details?.carts, 'quantity'),
        ZONEID: FIL_LOGISTICSADDRESSZONEID || 1,
      })
      .then((res) => {
        const markupValue = res?.data?.result?.data?.payload?.data[0]?.MARKUPVALUE;
        Setshipping(parseFloat(markupValue || 0));
      });
  };

  

  const userDetail = () => {
    var formData = new FormData();
    formData.append('client_id', dataUser.ACCOUNTNUM);

    axios.post('/v1/product_detail/get-user-data', formData).then((res) => {
      const list = [...(res?.data?.result?.data?.payload?.data[0]?.UMIDITA || [])];

      const FILSalesWetnessPercent = list?.map((item, index) => {
        const randomNumber =
          Math.random() * (parseFloat(item.MAXWETNESSPERCENT) - parseFloat(item.MINWETNESSPERCENT)) +
          parseFloat(item.MINWETNESSPERCENT);
        return { id: index, randomNumber: randomNumber, qty: item.TOQTY };
      });
      setSpedizione_esente(res?.data?.result?.data?.payload?.data[0]?.DATI_SPEDIZIONE[0]?.FILTRANSPORTCHARGE);
      setUserShipCarrierAccount(res?.data?.result?.data?.payload?.data[0]?.SHIPCARRIERACCOUNT || '-');
      SetUser([...FILSalesWetnessPercent]);
    });
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    userDetail();
    list();
  }, []);

  const shippingMethods = (() => {
    let result = [];

    if(spedizione_esente=='Sì'){
      result.push({
        pathForTranslatableTitle: 'Costo Spedizione esente',
        selectMe: () => {
          Setshipping(0);
          SetShipping_method(3);
          setShipCarrierAccount('');
          handleShipping();
        },
        isSelected: () => Shipping_method === 3,
      });
    }
    else{

      result.push({
        pathForTranslatableTitle: 'Spedizione tramite vettore Filmar',
        selectMe: () => {
          Setshipping(0);
          SetShipping_method(1);
          setShipCarrierAccount('');
          handleShipping();
        },
        isSelected: () => Shipping_method === 1,
      });
    }

    result.push({
      pathForTranslatableTitle: 'Ritiro tramite proprio vettore',
      selectMe: () => {
        SetShipping_method(2);
        Setshipping(0);
        setShipCarrierAccount(userShipCarrierAccount);
      },
      isSelected: () => Shipping_method === 2,
    });

    return result;
  })();

  const additionalIvaPrice = (totalForProducts + (Shipping_method === 3 ? 0 : shipping_) + totalSupplement) * User.getIvaAmount(dataUser);

  const ableToCheckout = useMemo(() => {
    if (!details || !details.carts?.length) {
      return false;
    }

    if (details.carts.every((item) => item.product_type === SAMPLE_PRODUCT_TYPE)) {
      return true;
    }

    return details.cartTotal > 0;
  }, [details, SAMPLE_PRODUCT_TYPE]);

  return (
    <>
      <div className="container checkout_page">
        <div>
          <h1 className="checkout_page_title">{t('Il tuo ordine')}</h1>
        </div>

        <div className="personal_data">
          <div className="checkout_section_title">
            <h2>{t('Dati personali')}</h2>
          </div>
          <div className="personal_data_content">
            <div className="personal_data_text">
              <p>{t('Ragione sociale')}</p>
              <h3>{dataUser?.business_name || dataUser?.parent_name}</h3>
            </div>
            <div className="personal_data_text">
              <p>{t('P.IVA')}</p>
              <h3>{dataUser?.vat_number}</h3>
            </div>
            <div className="personal_data_text">
              <p>{t('Email')}</p>
              <h3>{dataUser?.email}</h3>
            </div>
            <div className="personal_data_text">
              <p>{t('Telefono')}</p>
              <h3>{dataUser?.mobile_no}</h3>
            </div>
            {/* <div className="personal_data_text">
              <p>{t('Email')}</p>
              <h3>{dataUser?.email}</h3>
            </div>
            <div className="personal_data_text">
              <p>{t('Telefono')}</p>
              <h3>{dataUser?.mobile_no}</h3>
            </div> */}
          </div>
        </div>
        {detailsLoader.asJsx || (
          <>
            <div className="shipping_address checkout_section">
              <div className="checkout_section_title">
                <h2>{t('Indirizzo di spedizione')}</h2>
              </div>
              <div className="checkout_section_content">
                {details?.addresses?.map((item, index, items) => {
                  let selectThisItem = () => {
                    Setaddress(item.id);
                    SetFIL_LOGISTICSADDRESSZONEID(item.FIL_LOGISTICSADDRESSZONEID);
                  };
                  let isSingleItem = items.length === 1;

                  if (isSingleItem && address !== item.id) selectThisItem();

                  if (!address && item.ISPRIMARY === 'Sì') selectThisItem();

                  return (
                    <div className={`options_text ${address === item.id ? 'active' : ''}`} key={index}>
                      {!isSingleItem && (
                        <label>
                          <input
                            type="radio"
                            name="shippingAddress"
                            onClick={(e) => {
                              Setaddress(item.id);
                              SetFIL_LOGISTICSADDRESSZONEID(item.FIL_LOGISTICSADDRESSZONEID);
                            }}
                            defaultChecked={address === item.id}
                          />
                          <span className="checkbox" />
                        </label>
                      )}
                      <div>
                        <h3> {item.ADDRESSNAME}</h3>
                        <p>{item.ADDRESS}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {!isSampleOrder ? (
              <>
                <div className="shipping_options checkout_section">
                  <div className="checkout_section_title">
                    <h2>{t('Opzioni di spedizione')}</h2>
                  </div>
                  {availableShippingOptionList.map((item, index, items) => {
                    let selectThisItem = () => Setshipping_option(item.type);

                    let isSingleItem = items.length === 1;

                    if (isSingleItem && shipping_option !== item.type) selectThisItem();

                    return (
                      <div className="checkout_section_content">
                        <div className={`options_text ${shipping_option == item.type ? 'active' : ''}`}>
                          {!isSingleItem && (
                            <label>
                              <input
                                type="radio"
                                name="shippingOption"
                                onClick={selectThisItem}
                                checked={shipping_option === item.type}
                              />
                              <span className="checkbox" />
                            </label>
                          )}
                          <div>
                            <h3>{t(item.type === 'single' ? 'Spedizione unica' : 'Spedizione parziale')}</h3>
                            {/* <p>10 articoli - data di consegna 30/10/2022</p> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="shipping_method checkout_section">
                  <div className="checkout_section_title">
                    <h2>{t('Metodo di spedizione')}</h2>
                  </div>
                  <div className="checkout_section_content">
                    {shippingMethods.map((item, index, items) => {
                      let isSingleItem = items.length === 1;

                      if (isSingleItem && !item.isSelected()) item.selectMe();

                      return (
                        <div className={`options_text ${item.isSelected() ? 'active' : ''}`}>
                          {!isSingleItem && (
                            <label>
                              <input type="radio" name="Metodo" checked={item.isSelected()} onClick={item.selectMe} />
                              <span className="checkbox" />
                            </label>
                          )}
                          <div>
                            <h3>{t(item.pathForTranslatableTitle)}</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p> */}
                          </div>
                        </div>
                      );
                    })}
                    {/* {FIL_LOGISTICSADDRESSZONEID != 0 ? (
                  <div className={`options_text ${Shipping_method == 1 ? 'active' : ''}`}>
                    <label>
                      <input
                        type="radio"
                        name="Metodo"
                        onClick={(e) => {
                          Setshipping(0);
                          SetShipping_method(1);
                          handleShipping();
                        }}
                      />
                      <span className="checkbox" />
                    </label>
                    <div>
                      <h3>{t('Spedizione tramite vettore Filmar')}</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                  </div>
                ) : (
                  ''
                )} */}
                    {/* {dataUser?.BFCARRIERNAME ? (
                  ''
                ) : (
                  <div className={`options_text ${true ? 'active' : ''}`}>
                    <label>
                      <input
                        type="radio"
                        name="Metodo"
                        onClick={(e) => {
                          SetShipping_method(2);
                        }}
                      />
                      <span className="checkbox" />
                    </label>
                    <div>
                      <h3>{t('Ritiro tramite proprio vettore')}</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>
                  </div>
                )} */}
                  </div>
                </div>
                <div className="payment_method checkout_section">
                  <div className="checkout_section_title">
                    <h2>{t('Metodo pagamento')}</h2>
                  </div>
                  {[1].map((item, index, items) => {
                    let selectThisItem = () => SetPayment_method(1);

                    let isSingleItem = items.length === 1;

                    if (isSingleItem && Payment_method !== item) selectThisItem();

                    return (
                      <div className="checkout_section_content">
                        <div className={`options_text ${Payment_method == item ? 'active' : ''}`}>
                          {!isSingleItem && (
                            <label>
                              <input type="radio" name="Payment_method" onClick={selectThisItem} />
                              <span className="checkbox" />
                            </label>
                          )}
                          <div>
                            <h3>
                              {getTranslated(i18n, payment, { en: 'name_en', it: 'name_it ' }, 'name_it')} -{' '}
                              {getTranslated(i18n, paymentTerm, { en: 'name_en', it: 'name_it ' }, 'name_it')}
                            </h3>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            <div className="articles_section">
              <div className="articles_section_title">
                <h4>{t('Articoli')}</h4>
              </div>
              <div>
                {details?.carts?.map((item, index) =>
                  item.product_type === SAMPLE_PRODUCT_TYPE ? (
                    <PSample item={item} withBorderBottom />
                  ) : (
                    <CheckoutPurchase item={item} />
                  )
                )}
              </div>
            </div>
            <div className="checkout_bottom_section">
              <div className="order_note">
                {isSampleOrder ? (
                  <>
                    <div className="checkout_section_title">
                      <h2>{t('Referente')}</h2>
                    </div>
                    <div className="referent">
                      <input
                        className="text"
                        name="referent"
                        placeholder={t('Inserire un nominativo...')}
                        value={referent}
                        onChange={(e) => {
                          setReferent(e.target.value);
                        }}
                        type="text"
                      />
                    </div>
                  </>
                ) : null}
                <div className="checkout_section_title">
                  <h2>{t('Nota ordine')}</h2>
                  <p>{t("Aggiungi una nota d'ordine")}</p>
                </div>
                <div>
                  <form action>
                    <textarea
                      onChange={(e) => {
                        Setnote(e.target.value);
                      }}
                      name
                      id
                      placeholder={t('common.inserisci_il_tuo')}
                      defaultValue={''}
                    />
                  </form>
                </div>
              </div>
              {ableToCheckout ? (
                <div className="order_summary_section">
                  {!isSampleOrder ? (
                    <>
                      <div className="order_summary_section_title">
                        <h4>{t('Riepilogo ordine')}</h4>
                      </div>
                      <div className="order_summary_details">
                        <div className="order_summary_details_text">
                          <p>{t('Totale articoli')}</p>
                          <p>{formatPriceForView(totalForProducts)}</p>
                        </div>
                        {!!totalSupplement && (
                          <div className="order_summary_details_text">
                            <p>{t('Supplemento')}</p>
                            <p>{formatPriceForView(totalSupplement)}</p>
                          </div>
                        )}
                        <div className="order_summary_details_text">
                          <p>{t('Sub-totale')}</p>
                          <p>{formatPriceForView(details?.cartTotal)}</p>
                        </div>
                        {Shipping_method === 1 && (
                          <div className="order_summary_details_text">
                            <p>{t('Spese di spedizione')}</p>
                            <p>{formatPriceForView(shipping_)}</p>
                          </div>
                        )}
                        {!!additionalIvaPrice && (
                          <div className="order_summary_details_text">
                            <p>Iva 22%</p>
                            <p>{formatPriceForView(additionalIvaPrice)}</p>
                          </div>
                        )}
                        <div className="order_total">
                          <p>{t('Totale')}</p>
                          <p>{formatPriceForView(details?.cartTotal + (Shipping_method === 3 ? 0 : shipping_) + additionalIvaPrice)}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="order_proceed">
                    <Link className="back_cart_btn" to={getTranslated(i18n, cartPaths, { en: 'en', it: 'it' }, 'it')}>
                      {t('Torna al carrello')}
                    </Link>
                    {Shipping_method !== 0 || isSampleOrder ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          Placeorder();
                        }}
                        className="conform_order_btn"
                      >
                        {t('Conferma ordine')}{' '}
                      </a>
                    ) : (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="conform_order_btn"
                        style={{ cursor: 'default', opacity: 0.5 }}
                      >
                        {t('Conferma ordine')}
                      </a>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Checkout;